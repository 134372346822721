import { Button } from '@/components/button';
import { Card, CardContent, CardHeader } from '@/components/card';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/collapsible';
import { GoleeLogo } from '@/components/golee-logo';
import { Heading } from '@/components/heading';
import { ChevronDownIcon } from 'lucide-react';

type InvalidVersionModalProps = {
	onRefresh: () => void;
	minVersion: string;
	currentVersion: string;
};

export const InvalidVersionMessage = (props: InvalidVersionModalProps) => {
	return (
		<div className="mx-auto max-w-lg p-4">
			<Card>
				<CardHeader>
					<div className="flex justify-center">
						<GoleeLogo />
					</div>
				</CardHeader>
				<CardContent>
					<Heading level={3}>La tua versione dell'applicazione non è più supportata</Heading>
					<p>Per favore, aggiorna la pagina per poter utilizzare l'ultima versione dell'applicazione.</p>

					<div className="my-5 flex justify-center">
						<Button onClick={props.onRefresh}>Aggiorna ora</Button>
					</div>

					<Collapsible>
						<CollapsibleTrigger>
							<p className="flex items-center gap-1 text-sm text-muted-foreground">
								Maggiori informazioni <ChevronDownIcon className="h-4 w-4" />
							</p>
						</CollapsibleTrigger>
						<CollapsibleContent>
							<div className="mt-2 space-y-4 rounded border p-2 text-sm">
								<p>
									La tua versione: <code>{props.currentVersion}</code> <br />
									Versione minima supportata: <code>{props.minVersion}</code>
								</p>
							</div>
						</CollapsibleContent>
					</Collapsible>
				</CardContent>
			</Card>
		</div>
	);
};
