import { FormControl, FormItem, FormLabel, FormMessage } from '@/components/form';
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectSeparator,
	SelectTrigger,
	SelectValue,
} from '@/components/select';
import { cn } from '@/lib/utils';
import { ControllerRenderProps } from 'react-hook-form';
import { useLocations } from '../../context/locations/context';

type CountriesSelectProps = {
	formField: ControllerRenderProps<any, any>;
	label: string;
};

export const CountriesSelectField = ({ formField, ...props }: CountriesSelectProps) => {
	const { countries } = useLocations();

	return (
		<FormItem>
			<FormLabel>{props.label}</FormLabel>
			<Select onValueChange={formField.onChange} defaultValue={formField.value}>
				<FormControl>
					<SelectTrigger className={cn({ 'text-muted-foreground': !formField.value })}>
						<SelectValue placeholder={'Seleziona una regione'} />
					</SelectTrigger>
				</FormControl>
				<SelectContent>
					<SelectItem value={'ITA'} key={'ITA'}>
						Italia
					</SelectItem>
					<SelectSeparator />
					<SelectGroup>
						<SelectLabel>Estero</SelectLabel>
						{Object.keys(countries)
							.map(key => ({
								value: countries[key].key,
								label: countries[key].label,
							}))
							.filter(option => option.value !== 'ITA')
							.map(option => (
								<SelectItem value={option.value} key={option.value}>
									{option.label}
								</SelectItem>
							))}
					</SelectGroup>
				</SelectContent>
			</Select>
			<FormMessage />
		</FormItem>
	);
};
