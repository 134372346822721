import { ThemeProvider } from '@/components/theme';
import { I18nProvider } from '@gle/providers.i18n';
import { Settings } from 'luxon';
import { BrowserRouter } from 'react-router-dom';
import { InvalidVersionMessage, useMinVersionChecker } from './core/min-version-checker';
import { AppRouter } from './router/app-router';

Settings.defaultLocale = 'it';

const App = () => {
	const { isLoading, isBelowMinVersion, minVersion, forceRefresh } = useMinVersionChecker();

	if (isLoading) {
		return <></>;
	}

	if (isBelowMinVersion) {
		return (
			<InvalidVersionMessage
				onRefresh={forceRefresh}
				minVersion={minVersion}
				currentVersion={window.appVersion}
			/>
		);
	}

	return (
		<ThemeProvider>
			<I18nProvider>
				<BrowserRouter>
					<AppRouter />
				</BrowserRouter>
			</I18nProvider>
		</ThemeProvider>
	);
};

export default App;
