import { Card, CardContent } from '@/components/card';
import { cn } from '@/lib/utils';
import { HashIcon, ShirtIcon } from 'lucide-react';
import { OrgPerson } from '../../../../../context/org-person';
import { ProfileCardCallToAction, ProfileCardSectionTitle } from '../../components';

export const SportsCard = ({ orgPerson }: { orgPerson: OrgPerson }) => {
	const membershipAthleteNumber = orgPerson.metadata.athlete?.technicalProfile?.registrationNumber;
	const membershipTechnicalStaffNumber = orgPerson.metadata.technicalStaff?.registrationNumber;
	const membershipManagerialStaffNumber = orgPerson.metadata.managerialStaff?.registrationNumber;

	const membershipNumbers = [
		membershipAthleteNumber,
		membershipTechnicalStaffNumber,
		membershipManagerialStaffNumber,
	].filter(Boolean);

	const groups = orgPerson.groups?.filter(group => !group.groupType);

	return (
		<div>
			<ProfileCardSectionTitle>Gruppi e matricola/tessera</ProfileCardSectionTitle>
			<Card>
				<CardContent className="flex flex-col p-0">
					<div className="flex items-center gap-2 border-b border-solid border-muted px-2 py-3">
						<span>
							<ShirtIcon className="h-4 w-4 text-muted-foreground" />
						</span>
						<span>{groups ? groups.map(group => group.groupName).join(', ') : 'Nessun gruppo'}</span>
					</div>
					<div className="flex items-center gap-2 px-2 py-3">
						<span>
							<HashIcon className="h-4 w-4 text-muted-foreground" />
						</span>{' '}
						<span className={cn('')}>{membershipNumbers.length ? membershipNumbers.join(', ') : '-'}</span>
					</div>

					<ProfileCardCallToAction to={'sports'} label={'Altri dati sportivi'} />
				</CardContent>
			</Card>
		</div>
	);
};
