import { cn } from '@/lib/utils';
import { ProductPass } from '../types';
import { calculateRemainingDays } from '../utils/calculate-remaining-days';
import { calculateTotalDays } from '../utils/calculate-total-days';
import { getProductPassStatus } from '../utils/get-product-pass-status';

type ProductPassProgressBarProps = {
	pass: ProductPass;
};

export const ProductPassProgressBar = (props: ProductPassProgressBarProps) => {
	const { startDate, expirationDate } = props.pass;

	const passStatus = getProductPassStatus(props.pass.state, startDate, expirationDate);

	const totalDays = calculateTotalDays(startDate, expirationDate);
	const remainingDays = calculateRemainingDays(startDate, expirationDate);

	const progressPercentage = ((totalDays - remainingDays) / totalDays) * 100;

	return (
		<div className="space-y-2">
			{passStatus === 'active' ? (
				<div className="relative h-2 overflow-hidden rounded-md bg-accent">
					<div
						className={cn(
							`flex h-full items-center justify-center bg-zinc-600 text-xs font-semibold text-background transition-all duration-300 ease-in-out`
						)}
						style={{
							width: `${progressPercentage}%`,
						}}></div>
				</div>
			) : (
				<div className="border-t border-solid border-muted"></div>
			)}

			<p className="text-sm text-muted-foreground">
				{remainingDays} giorn{remainingDays !== 1 ? 'i' : 'o'} alla scadenza
			</p>
		</div>
	);
};
