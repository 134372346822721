import { Spinner } from '@/components/spinner';
import {
	hasCameraPermission,
	isAppVersionGte,
	isFromApp,
	sendMessageToReactNative,
} from '@golee/react-native-web-bridge';
import { CameraIcon } from 'lucide-react';
import { ChangeEvent, useRef, useState } from 'react';
import { uploadFileToPepa } from '../../api/pepa/files';
import { useBoolean } from '../../hooks/use-boolean';

export const ImageUploader = ({
	url,
	style,
	onChange,
}: {
	url?: string | null;
	style?: React.CSSProperties;
	onChange?: (url?: string) => void;
}) => {
	const [file, setFile] = useState<string | undefined | null>(url);
	const [isLoading, setIsLoading] = useBoolean();

	const ref = useRef<any>();

	const onChangeImage = async (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const file = e.target.files[0];

			let formData = new FormData();
			formData.append('file', file);
			const config = {
				onUploadProgress: function () {
					setIsLoading(true);
				},
			};
			const resp = await uploadFileToPepa({ formData, config });
			setIsLoading(false);
			setFile(resp.data.magic_url);
			onChange?.(resp.data.magic_url);
		}
	};

	return (
		<div
			style={{
				width: 120,
				height: 120,
				padding: 10,
				backgroundColor: '#f5f5f5',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				borderRadius: 10,
				border: '1px dashed var(--gray-lighter-color)',
				...style,
			}}>
			{isLoading ? (
				<Spinner />
			) : (
				<div
					style={{
						width: 100,
						height: 100,
						borderRadius: '10px',
						backgroundImage: `url("${file}")`,
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
					}}
					onClick={() => {
						if (isFromApp() && isAppVersionGte('1.2.2') && !hasCameraPermission()) {
							sendMessageToReactNative('request-camera-permission', {});
						}

						ref.current.click();
					}}>
					<input
						type="file"
						accept="image/*"
						onChange={onChangeImage}
						style={{ visibility: 'hidden' }}
						ref={ref}
					/>

					{!file && (
						<div className="flex items-center justify-center pt-2">
							<CameraIcon className="h-7 w-7 text-muted-foreground" />
						</div>
					)}
				</div>
			)}
		</div>
	);
};
