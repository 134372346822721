import { Avatar, AvatarFallback, AvatarImage } from '@/components/avatar';
import { Card, CardContent } from '@/components/card';
import { ProfileCardCallToAction } from '../../components';

type GeneralCardProps = {
	name: string;
	surname: string;
	fiscalCode: string;
	birthdate?: string;
	avatarUrl?: string | null;
};

export const GeneralCard = (props: GeneralCardProps) => {
	return (
		<Card>
			<CardContent className="p-0">
				<div className="flex items-center gap-4 p-4">
					<Avatar className="h-16 w-16">
						<AvatarImage alt="Profile picture" src={props.avatarUrl || undefined} />
						<AvatarFallback>
							{props.name[0]}
							{props.surname[0]}
						</AvatarFallback>
					</Avatar>
					<div className="flex flex-col">
						<h3 className="text-lg font-semibold">
							{props.name} {props.surname}
						</h3>
						<p className="text-muted-foreground">{props.fiscalCode}</p>
					</div>
				</div>
				<ProfileCardCallToAction to={'general'} label={'Maggiori dettagli'} />
			</CardContent>
		</Card>
	);
};
