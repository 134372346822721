import { Button } from '@/components/button';
import { Card } from '@/components/card';
import { formatDate } from '@/utils';
import { useI18n } from '@gle/providers.i18n';
import { DownloadIcon, PinIcon } from 'lucide-react';
import { Post } from '../../../../api/club/posts-feed';
import { Divider } from '../../../../components/divider';
import { TextWithLinksRenderer } from '../../../../components/text-with-links-renderer';
import Reactions from './post-reactions';

export const PostItem = ({ post }: { post: Post }) => {
	const { messages } = useI18n();
	const reaction = post.reactions.length ? post.reactions[0].reaction : '';
	const attachment = post.attachment;

	return (
		<Card className={'overflow-hidden p-0'}>
			<div className={'flex items-start justify-between bg-accent p-2'}>
				<div className={'flex flex-col'}>
					<p className="text-sm font-medium">{post.authorName}</p>
					<p className="text-xs text-muted-foreground first-letter:capitalize">
						{formatDate(post.createdAt, "EEE dd/MM/yyyy 'alle' HH:mm")}
					</p>
				</div>
				{post.isPinned && (
					<p className="mt-1 flex items-center gap-1 text-xs text-muted-foreground">
						<p className="flex items-center gap-1">
							<PinIcon size={14} className="text-muted-foreground" /> Fissato
						</p>
					</p>
				)}
			</div>
			<div className="p-2">
				<div className={'space mb-3 mt-2'}>
					{post.content.html ? (
						<div
							className="html-content-preview text-sm"
							dangerouslySetInnerHTML={{ __html: post.content.html }}></div>
					) : (
						<TextWithLinksRenderer text={post.content.data} />
					)}
				</div>
				{attachment && (
					<div className={'mt-4 flex flex-row items-center gap-1.5'}>
						<a href={attachment?.url} target={'_blank'}>
							<Button size="sm" variant={'secondary'}>
								<DownloadIcon size={18} className="mr-2" />
								{messages['download']}
							</Button>
						</a>
						<p className="text-sm text-muted-foreground">{attachment.fileName}</p>
					</div>
				)}
				<Divider style={{ marginBlock: 10 }} />
				<Reactions reaction={reaction} postId={post._id} type={post.type} />
			</div>
		</Card>
	);
};
