import { cn } from '@/lib/utils';
import { CalendarIcon } from 'lucide-react';
import { DateTime } from 'luxon';
import { Label } from '../label';

type ReadOnlyValueProps = {
	label: string;
	value?: string;
	type?: 'text' | 'date' | 'textarea';
};

const getDateAsObject = (value: string) => {
	return DateTime.fromFormat(value, 'dd/mm/yyyy').isValid
		? DateTime.fromFormat(value, 'dd/mm/yyyy').toJSDate()
		: DateTime.fromISO(value).toJSDate();
};

export const ReadOnlyValue = (props: ReadOnlyValueProps) => {
	if (props.type === 'date') {
		return (
			<div>
				<Label className="text-muted-foreground">{props.label}</Label>
				<div className="mt-1.5 flex items-center justify-start rounded-md border border-solid border-muted px-3 py-2">
					<CalendarIcon className="mr-3 h-4 w-4 text-muted-foreground" />
					{props.value ? (
						DateTime.fromJSDate(getDateAsObject(props.value))
							.setLocale('it')
							.toLocaleString(DateTime.DATE_FULL)
					) : (
						<span className="text-sm text-muted-foreground">{'Non impostata'}</span>
					)}
				</div>
			</div>
		);
	}

	if (props.type === 'textarea') {
		return (
			<div>
				<Label className="text-muted-foreground">{props.label}</Label>
				<div
					className={cn('mt-1.5 min-h-20 rounded-md border border-solid border-muted px-3 py-2', {
						'text-muted-foreground': !props.value,
					})}>
					{props.value || '-'}
				</div>
			</div>
		);
	}

	return (
		<div>
			<Label className="text-muted-foreground">{props.label}</Label>
			<div
				className={cn(
					'mt-1.5 flex min-h-10 items-center rounded-md border border-solid border-muted px-3 py-2',
					{
						'text-muted-foreground': !props.value,
					}
				)}>
				{props.value || '-'}
			</div>
		</div>
	);
};
