import { Button } from '@/components/button';
import { useI18n } from '@gle/providers.i18n';
import {
	hasCameraPermission,
	isAppVersionGte,
	isFromApp,
	sendMessageToReactNative,
} from '@golee/react-native-web-bridge';
import { UploadIcon } from 'lucide-react';
import React, { ChangeEvent } from 'react';

type LoadFileButtonProps = {
	onFileLoaded: (loadedFile: File) => void;
};

const LoadFileButton = (props: LoadFileButtonProps) => {
	const { messages } = useI18n();

	const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) throw new Error(`Cannot upload file`);
		const file = e.target.files[0];
		if (!getMimes().includes(file.type))
			throw new Error(`${messages['type']} ${file.type} ${messages['not_handled']}`);

		props.onFileLoaded(file);
	};

	const getMimes = (): string => {
		return 'application/pdf,image/jpeg,image/png,image/jpg,text/plain,text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel';
	};

	const hiddenFileInput: any = React.useRef(null);
	const handleOnClick = () => {
		if (!hiddenFileInput.current?.click) return;

		if (isFromApp() && isAppVersionGte('1.2.2') && !hasCameraPermission()) {
			sendMessageToReactNative('request-camera-permission', {});
		}

		hiddenFileInput.current.click();
	};
	return (
		<div className={'uploaderWrapper'}>
			<Button onClick={handleOnClick}>
				<UploadIcon size={18} className="mr-2" />
				{'Carica'}
			</Button>
			<input
				accept={getMimes()}
				type="file"
				ref={hiddenFileInput}
				onChange={onChange}
				style={{ display: 'none' }}
			/>
		</div>
	);
};

export default LoadFileButton;
