import { ReactNode } from 'react';
import { usePermissions } from './use-permissions';

export type Permission = 'feed:read' | 'agreements:read' | 'financial:products:read';

type PermissionWrapperProps = {
	permission: Permission;
	children?: ReactNode;
};

export const PermissionWrapper: React.FC<PermissionWrapperProps> = ({ ...props }) => {
	const { identity } = usePermissions();

	const permission = identity?.permissions[props.permission];

	if (permission === 'allowed' || !props.permission) {
		return <>{props.children}</>;
	}

	return <></>;
};
