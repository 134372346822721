import { useSeason } from '../../context/season';

export const useReadableSeason = () => {
	const { useAnnualSeason } = useSeason();

	const readableSeason = (selectedSeason: string): string => {
		if (!selectedSeason) return '';

		if (useAnnualSeason) {
			return `20${selectedSeason.slice(0, 2)}`;
		}

		return `20${selectedSeason.slice(0, 2)}/${selectedSeason.slice(2, 4)}`;
	};

	return readableSeason;
};
