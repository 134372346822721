import { Button } from '@/components/button';
import { DatePicker } from '@/components/date-picker';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormSubmitButtons } from '@/components/form';
import { Heading } from '@/components/heading';
import { Input } from '@/components/input';
import { InputPhone, isValidPhoneNumber } from '@/components/input-phone';
import { ReadOnlyValue } from '@/components/read-only-input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/select';
import { useToast } from '@/components/toast';
import { useI18n } from '@gle/providers.i18n';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { validateFiscalCode } from '../../../../../@/utils/validate-fiscal-code';
import { CountriesSelectField } from '../../../../../components/selector/countries-select-field';
import { TownsSelectField } from '../../../../../components/selector/italian-towns-select-field';
import { ProvincesSelectField } from '../../../../../components/selector/provinces-select-field';
import { RegionsSelectField } from '../../../../../components/selector/regions-select-field';
import { createOrgPersonParent, updateOrgPersonParent } from '../../../../../context/org-person/api';
import { DeleteParentButton } from './parent-delete-button';

const formSchema = z.object({
	_id: z.string().optional(),
	name: z.string().min(1, 'Campo obbligatorio'),
	surname: z.string().optional(),
	kinship: z.string().optional(),
	birthdate: z.coerce.date().nullable().optional(),
	fiscal_code: z.string().optional(),
	birthplace: z.string().optional(),
	recap: z
		.object({
			email: z.string().email('Email non valida').optional(),
			phone: z
				.string()
				.refine(value => !value || isValidPhoneNumber(value), {
					message: 'Telefono non valido',
				})
				.optional(),
		})
		.optional(),
	address: z
		.object({
			nation: z.string().nullable().optional(),
			region: z.string().nullable().optional(),
			province: z.string().nullable().optional(),
			town: z.string().nullable().optional(),
			address: z.string().optional(),
			civic_number: z.string().optional(),
			cap: z.string().optional(),
		})
		.optional(),
});

type ParentsFormProps = {
	orgPersonId: string;
	season: string;
	initialValues: z.infer<typeof formSchema>;
	isEdit?: boolean;
	onComplete: () => void;
	onCancel: () => void;
};

export const ParentsForm = (props: ParentsFormProps) => {
	const { toast } = useToast();

	const [isEdit, setIsEdit] = useState<boolean>(!!props.isEdit);
	const [isSaving, setIsSaving] = useState<boolean>(false);

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: props.initialValues,
	});

	const onSubmit = async (values: z.infer<typeof formSchema>) => {
		setIsSaving(true);

		if (values.fiscal_code) {
			const isValidFiscalCode = await validateFiscalCode(values.fiscal_code);

			if (!isValidFiscalCode) {
				form.setError('fiscal_code', { message: 'Codice fiscale non valido' }, { shouldFocus: true });
				setIsSaving(false);
				return;
			}
		}

		try {
			const payload = {
				...values,
				birthdate: values.birthdate ? values.birthdate.toISOString() : undefined,
				address: {
					...values.address,
					// fuck ourselves and towns with same names.
					town: values.address?.town ? values.address?.town.split('-')[0] : undefined,
				},
			};

			if (props.initialValues._id) {
				await updateOrgPersonParent(props.orgPersonId, payload, props.season);
			} else {
				await createOrgPersonParent(props.orgPersonId, payload, props.season);
			}

			toast({
				title: 'Salvataggio completato',
				description: 'I dati sono stati aggiornati con successo',
				variant: 'success',
			});

			props.onComplete();
		} catch (error) {
			toast({
				variant: 'destructive',
				title: 'Errore',
				description: 'Si è verificato un errore imprevisto, riprova più tardi',
			});
		} finally {
			setIsSaving(false);
		}
	};

	if (!isEdit) {
		return (
			<ReadOnlyParentsForm
				initialValues={props.initialValues}
				onEdit={() => {
					setIsEdit(true);
				}}
				onDelete={() => props.onComplete()}
				orgPersonId={props.orgPersonId}
				season={props.season}
			/>
		);
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="mx-auto flex flex-col gap-4">
				<div className="flex gap-4">
					<FormField
						control={form.control}
						name="name"
						render={({ field }) => (
							<FormItem className="flex-[0.5]">
								<FormLabel>Nome</FormLabel>
								<Input placeholder="" type="text" {...field} />
								<FormMessage />
							</FormItem>
						)}
					/>

					<FormField
						control={form.control}
						name="surname"
						render={({ field }) => (
							<FormItem className="flex-[0.5]">
								<FormLabel>Cognome</FormLabel>
								<FormControl>
									<Input placeholder="" type="text" {...field} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				<FormField
					control={form.control}
					name="fiscal_code"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Codice fiscale</FormLabel>
							<FormControl>
								<Input placeholder="" type="text" {...field} />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="birthdate"
					render={({ field }) => (
						<FormItem className="flex flex-col">
							<FormLabel>Data di nascita</FormLabel>
							<DatePicker value={field.value} onChange={field.onChange} isClearable />
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="kinship"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Grado di parentela</FormLabel>
							<Select onValueChange={field.onChange} defaultValue={field.value}>
								<FormControl>
									<SelectTrigger>
										<SelectValue placeholder="Seleziona un valore" />
									</SelectTrigger>
								</FormControl>
								<SelectContent>
									<SelectItem value="FTR">Padre</SelectItem>
									<SelectItem value="MTR">Madre</SelectItem>
									<SelectItem value="LGG">Tutore legale</SelectItem>
									<SelectItem value="SBL">Fratelli</SelectItem>
									<SelectItem value="OTH">Altro</SelectItem>
								</SelectContent>
							</Select>
							<FormMessage />
						</FormItem>
					)}
				/>
				<Heading level={3}>Contatti ed indirizzo</Heading>
				<FormField
					control={form.control}
					name="recap.email"
					render={({ field }) => (
						<FormItem className="flex flex-col">
							<FormLabel>Email</FormLabel>
							<Input placeholder="" type="text" {...field} />
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="recap.phone"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Telefono</FormLabel>
							<FormControl>
								<InputPhone value={field.value} onChange={field.onChange} defaultCountry="IT" />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<div className="flex gap-4">
					<FormField
						control={form.control}
						name="address.address"
						render={({ field }) => (
							<FormItem className="flex flex-[0.7] flex-col">
								<FormLabel>Indirizzo</FormLabel>
								<Input placeholder="" type="text" {...field} />
								<FormMessage />
							</FormItem>
						)}
					/>

					<FormField
						control={form.control}
						name="address.civic_number"
						render={({ field }) => (
							<FormItem className="flex flex-[0.3] flex-col">
								<FormLabel>Num. Civico</FormLabel>
								<Input placeholder="" type="text" {...field} />
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				<FormField
					control={form.control}
					name="address.cap"
					render={({ field }) => (
						<FormItem className="flex flex-col">
							<FormLabel>CAP</FormLabel>
							<Input placeholder="" type="text" {...field} />
							<FormMessage />
						</FormItem>
					)}
				/>

				<FormField
					control={form.control}
					name="address.town"
					render={({ field }) => <TownsSelectField formField={field} label="Comune" />}
				/>

				<FormField
					control={form.control}
					name="address.province"
					render={({ field }) => <ProvincesSelectField formField={field} label="Provincia" />}
				/>
				<FormField
					control={form.control}
					name="address.region"
					render={({ field }) => <RegionsSelectField formField={field} label="Regione" />}
				/>

				<FormField
					control={form.control}
					name="address.nation"
					render={({ field }) => <CountriesSelectField formField={field} label="Nazione" />}
				/>

				<FormSubmitButtons
					isSaving={isSaving}
					onCancel={() => {
						if (props.isEdit) {
							props.onCancel();
						} else {
							setIsEdit(false);
						}
					}}
				/>
			</form>
		</Form>
	);
};

const ReadOnlyParentsForm = (
	props: Pick<ParentsFormProps, 'initialValues' | 'season' | 'orgPersonId'> & {
		onEdit: () => void;
		onDelete?: () => void;
	}
) => {
	const { messages } = useI18n();

	return (
		<div className="flex flex-col gap-4">
			<div className="flex w-full gap-4">
				<div className="w-full">
					<ReadOnlyValue label="Nome" value={props.initialValues.name} type="text" />
				</div>
				<div className="w-full">
					<ReadOnlyValue label="Cognome" value={props.initialValues.surname} type="text" />
				</div>
			</div>

			<ReadOnlyValue label="Codice fiscale" value={props.initialValues.fiscal_code} type="text" />
			<ReadOnlyValue label="Data di nascita" value={props.initialValues.birthdate?.toISOString()} type="date" />
			<ReadOnlyValue label="Luogo di nascita" value={props.initialValues.birthplace} type="text" />
			<ReadOnlyValue
				label="Grado di parentela"
				value={
					props.initialValues.kinship ? messages[`kinship.${props.initialValues.kinship}`] : 'Non impostata'
				}
				type="text"
			/>

			<Heading level={3}>Contatti ed indirizzo</Heading>
			<ReadOnlyValue label="Email" value={props.initialValues.recap?.email} type="text" />
			<ReadOnlyValue label="Telefono" value={props.initialValues.recap?.phone} type="text" />
			<ReadOnlyValue
				type="textarea"
				label="Indirizzo"
				value={[
					props.initialValues.address?.address,
					props.initialValues.address?.civic_number,
					props.initialValues.address?.cap,
					props.initialValues.address?.town,
					props.initialValues.address?.province,
					props.initialValues.address?.region,
					props.initialValues.address?.nation,
				]
					.filter(part => !!part)
					.join(', ')}
			/>

			<div className="flex justify-between">
				<div>
					{props.initialValues._id && (
						<DeleteParentButton
							orgPersonId={props.orgPersonId}
							parentId={props.initialValues._id}
							season={props.season}
							onComplete={props.onDelete}
						/>
					)}
				</div>

				<Button variant="secondary" onClick={props.onEdit} type="button">
					Modifica dati
				</Button>
			</div>
		</div>
	);
};
