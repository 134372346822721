import { ReadOnlyValue } from '@/components/read-only-input';
import { useI18n } from '@gle/providers.i18n';
import { CustomField } from '../../hooks/custom-input-fields/types';
import { useCustomSelect } from '../../hooks/use-custom-select';

export const ReadOnlyCustomValueField = (props: { field: CustomField; value: any; label?: string }) => {
	const { messages } = useI18n();

	switch (props.field.type) {
		case 'BOOLEAN':
			return (
				<ReadOnlyValue
					type="text"
					label={messages[props.field.labelKey]}
					value={props.value ? messages['yes'] : messages['no']}
				/>
			);

		case 'NUMBER':
		case 'TEXT':
			return <ReadOnlyValue type="text" label={messages[props.field.labelKey]} value={props.value} />;

		case 'DATE':
			return <ReadOnlyValue type="date" label={messages[props.field.labelKey]} value={props.value} />;

		case 'SELECT':
			return (
				<ReadOnlyValue
					type="text"
					label={messages[props.field.labelKey]}
					value={messages[`${props.field.labelKey}.${props.value}`]}
				/>
			);

		case 'CUSTOM-SELECT':
			return (
				<CustomOptionValue
					optionsKey={props.field.optionsKey}
					value={props.value}
					label={messages[props.field.labelKey]}
				/>
			);

		default:
			return <></>;
	}
};

export const CustomOptionValue = (props: { optionsKey: string; value: string; label: string }) => {
	const { options } = useCustomSelect(props.optionsKey);

	const getValue = () => {
		const item = options.find(option => option.value === props.value);
		return item?.label;
	};

	return <ReadOnlyValue type="text" label={props.label} value={getValue()} />;
};
