import { Card, CardContent } from '@/components/card';
import { cn } from '@/lib/utils';
import { MailIcon, MapPinIcon, PhoneIcon } from 'lucide-react';
import { OrgPerson } from '../../../../../context/org-person';
import { ProfileCardCallToAction, ProfileCardSectionTitle } from '../../components';

type ContactsCardProps = {
	email?: string;
	phone?: string;
	address?: OrgPerson['personMetadata']['address'];
};

export const ContactsCard = (props: ContactsCardProps) => {
	const addressAsString = [
		props.address?.address,
		props.address?.num,
		props.address?.cap,
		props.address?.town,
		props.address?.province,
		props.address?.region,
		props.address?.nation,
	]
		.filter(part => !!part)
		.join(', ');

	return (
		<div>
			<ProfileCardSectionTitle>Contatti ed indirizzo</ProfileCardSectionTitle>
			<Card>
				<CardContent className="flex flex-col p-0">
					<div className="flex items-center gap-2 border-b border-solid border-muted px-2 py-3">
						<span>
							<MailIcon className="h-4 w-4 text-muted-foreground" />
						</span>
						<span className={cn('')}>{props.email || 'Non impostata'}</span>
					</div>
					<div className="flex items-center gap-2 border-b border-solid border-muted px-2 py-3">
						<span>
							<PhoneIcon className="h-4 w-4 text-muted-foreground" />
						</span>
						<span className={cn('')}>{props.phone || 'Non impostato'}</span>
					</div>
					<div className="flex items-center gap-2 px-2 py-3">
						<span>
							<MapPinIcon className="h-4 w-4 text-muted-foreground" />{' '}
						</span>
						<span className={cn('')}>{addressAsString || 'Non impostato'}</span>
					</div>
					<ProfileCardCallToAction to={'contacts'} label={'Maggiori dettagli'} />
				</CardContent>
			</Card>
		</div>
	);
};
