import { Alert, AlertDescription, AlertTitle } from '@/components/alert';
import { FormField, FormItem } from '@/components/form';
import { InfoIcon } from 'lucide-react';
import { UseFormReturn } from 'react-hook-form';
import { OrgPersonAthleteMetadata, OrgPersonMetadata } from '../../context/org-person';
import { CustomField } from '../../hooks/custom-input-fields/types';
import { CustomInputField } from './custom-input-field';
import { ReadOnlyCustomValueField } from './custom-value-field';

type CustomInputFieldProps = {
	fields: CustomField[];
	form: UseFormReturn<any>;
};

export const CustomInputFields = ({ form, ...props }: CustomInputFieldProps) => {
	const editableFields = props.fields.filter(field => field.isEditableFromOrgPerson);

	return (
		<>
			{editableFields.map(field => {
				const fieldKey = field.key.replace('metadata.', '');

				return (
					<FormField
						key={fieldKey}
						control={form.control}
						name={fieldKey}
						render={({ field: formField }) => (
							<FormItem className="flex flex-col">
								<CustomInputField formField={formField} field={field} />
							</FormItem>
						)}
					/>
				);
			})}

			{editableFields.length !== props.fields.length && (
				<Alert variant={'warning'} className="mt-4">
					<InfoIcon className="h-4 w-4" />
					<AlertTitle>Nota</AlertTitle>
					<AlertDescription>
						Alcuni dati non sono modificabili direttamente da te. Se hai bisogno di apportare altre
						modifiche, contatta la tua società sportiva.
					</AlertDescription>
				</Alert>
			)}
		</>
	);
};

type ReadOnlyCustomInputFieldsProps = {
	fields: CustomField[];
	values: {
		person: OrgPersonMetadata;
		athlete: OrgPersonAthleteMetadata;
	};
};

export const ReadOnlyCustomInputFields = (props: ReadOnlyCustomInputFieldsProps) => {
	return (
		<>
			{props.fields.map(field => {
				const fieldKey = field.key.replace('metadata.', '');
				return (
					<ReadOnlyCustomValueField
						field={field}
						value={getValueByDotNotation(props.values, fieldKey)}
						key={fieldKey}
					/>
				);
			})}
		</>
	);
};

const getValueByDotNotation = (obj: any, dotNotation: string): any => {
	return dotNotation.split('.').reduce((acc, key) => acc?.[key], obj);
};
