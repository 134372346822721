import { FormControl, FormItem, FormLabel, FormMessage } from '@/components/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/select';
import { cn } from '@/lib/utils';
import { ControllerRenderProps } from 'react-hook-form';
import { useLocations } from '../../context/locations/context';

type ProvincesSelectProps = {
	formField: ControllerRenderProps<any, any>;
	label: string;
};

export const ProvincesSelectField = ({ formField, ...props }: ProvincesSelectProps) => {
	const { provinces } = useLocations();

	return (
		<FormItem>
			<FormLabel>{props.label}</FormLabel>
			<Select onValueChange={formField.onChange} defaultValue={formField.value}>
				<FormControl>
					<SelectTrigger className={cn({ 'text-muted-foreground': !formField.value })}>
						<SelectValue placeholder={'Seleziona una provincia'} />
					</SelectTrigger>
				</FormControl>
				<SelectContent>
					{Object.keys(provinces)
						.map(key => ({
							value: provinces[key].key,
							label: provinces[key].label,
						}))
						.map(option => (
							<SelectItem value={option.value} key={option.value}>
								{option.label}
							</SelectItem>
						))}
				</SelectContent>
			</Select>
			<FormMessage />
		</FormItem>
	);
};
