import { GoleeLogo } from '@/components/golee-logo';
import { BellIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Menu } from './menu';

export const Header = (props: { hideNavigation: boolean }) => {
	return (
		<div
			className={`fixed left-0 top-0 z-10 w-full border-b border-solid border-b-muted bg-background`}
			style={{ height: '4rem' }}>
			<div className={'mx-auto flex h-16 items-center justify-between px-4'}>
				{!props.hideNavigation && <Menu />}

				<div className={'flex grow justify-center'}>
					<Link to={'/home'}>
						<GoleeLogo size="md" />
					</Link>
				</div>

				{!props.hideNavigation && (
					<div className={'flex gap-2 lg:hidden'}>
						<Link to="/notifications" className={'w-[28px]'}>
							<BellIcon size={24} />
						</Link>
					</div>
				)}
			</div>
		</div>
	);
};
