import { Avatar } from '@/components/avatar';
import { cn } from '@/lib/utils';

export const MimeTypeIcon = (props: { mimeType?: string }) => {
	return (
		<Avatar
			className={cn(
				`flex h-8 w-8 items-center justify-center rounded-full border border-muted bg-slate-100 shadow`,
				{
					'border-muted bg-slate-100': props.mimeType !== 'application/pdf',
					'border-rose-200 bg-rose-100': props.mimeType === 'application/pdf',
				}
			)}>
			<Icon mimeType={props.mimeType} />
		</Avatar>
	);
};

const Icon = (props: { mimeType?: string }) => {
	if (!props.mimeType) return <File />;

	if (props.mimeType?.includes('image')) {
		return <Image />;
	}

	switch (props.mimeType) {
		case 'application/pdf': {
			return <Pdf />;
		}

		case 'text/plain': {
			return <Text />;
		}

		case 'text/csv':
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
		case 'application/vnd.ms-excel': {
			return <Xlsx />;
		}

		default: {
			return <File />;
		}
	}
};

const Pdf = () => {
	return (
		<svg
			className="h-4 w-4"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M8.28448 18.636C8.49448 18.513 8.72398 18.393 8.97298 18.279C8.76264 18.5926 8.53738 18.896 8.29798 19.188C7.87798 19.6935 7.55098 19.962 7.34548 20.046C7.32842 20.0532 7.31088 20.0593 7.29298 20.064C7.27801 20.0433 7.26496 20.0212 7.25398 19.998C7.16998 19.833 7.17298 19.674 7.31398 19.458C7.47298 19.2105 7.79248 18.927 8.28448 18.636ZM11.967 16.1655C11.7885 16.203 11.6115 16.2405 11.433 16.2825C11.6974 15.7645 11.9475 15.2393 12.183 14.7075C12.4201 15.1469 12.6753 15.5763 12.948 15.9945C12.6225 16.0425 12.294 16.0995 11.967 16.1655ZM15.7545 17.574C15.5215 17.3861 15.3033 17.1805 15.102 16.959C15.444 16.9665 15.753 16.992 16.02 17.04C16.4955 17.1255 16.719 17.2605 16.797 17.3535C16.8216 17.3795 16.8355 17.4138 16.836 17.4495C16.8307 17.5554 16.7998 17.6583 16.746 17.7495C16.7157 17.8226 16.6672 17.8867 16.605 17.9355C16.574 17.9545 16.5375 17.9625 16.5015 17.958C16.3665 17.9535 16.1145 17.859 15.7545 17.574ZM12.417 10.455C12.357 10.821 12.255 11.241 12.117 11.6985C12.0661 11.5272 12.0216 11.3541 11.9835 11.1795C11.8695 10.65 11.853 10.2345 11.9145 9.94654C11.9715 9.68104 12.0795 9.57454 12.2085 9.52204C12.2777 9.49154 12.3509 9.47133 12.426 9.46204C12.4455 9.50704 12.468 9.60004 12.474 9.75904C12.4815 9.94204 12.4635 10.1745 12.417 10.4565V10.455Z"
				fill="#fb7185"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6 0H13.9395C14.3373 8.49561e-05 14.7188 0.158176 15 0.4395L20.5605 6C20.8418 6.28124 20.9999 6.66271 21 7.0605V21C21 21.7956 20.6839 22.5587 20.1213 23.1213C19.5587 23.6839 18.7956 24 18 24H6C5.20435 24 4.44129 23.6839 3.87868 23.1213C3.31607 22.5587 3 21.7956 3 21V3C3 2.20435 3.31607 1.44129 3.87868 0.87868C4.44129 0.316071 5.20435 0 6 0V0ZM14.25 2.25V5.25C14.25 5.64782 14.408 6.02936 14.6893 6.31066C14.9706 6.59196 15.3522 6.75 15.75 6.75H18.75L14.25 2.25ZM6.2475 20.502C6.3825 20.772 6.5925 21.0165 6.9045 21.1305C7.215 21.243 7.5225 21.1905 7.7745 21.0855C8.2515 20.8905 8.727 20.4315 9.1635 19.9065C9.663 19.305 10.188 18.516 10.695 17.6415C11.6738 17.3514 12.6761 17.1476 13.6905 17.0325C14.1405 17.607 14.6055 18.102 15.0555 18.4575C15.4755 18.7875 15.96 19.062 16.4565 19.083C16.7269 19.0964 16.9947 19.0239 17.2215 18.876C17.454 18.7245 17.6265 18.5055 17.7525 18.252C17.8875 17.9805 17.97 17.697 17.9595 17.4075C17.95 17.1221 17.8443 16.8482 17.6595 16.6305C17.3205 16.2255 16.7655 16.0305 16.2195 15.933C15.5572 15.8304 14.8851 15.8052 14.217 15.858C13.6528 15.0604 13.1608 14.214 12.747 13.329C13.122 12.339 13.4025 11.403 13.527 10.638C13.581 10.311 13.6095 9.999 13.599 9.717C13.5971 9.43708 13.532 9.1612 13.4085 8.91C13.3373 8.77137 13.2362 8.65031 13.1125 8.55561C12.9887 8.46091 12.8454 8.39495 12.693 8.3625C12.39 8.298 12.078 8.3625 11.7915 8.478C11.226 8.703 10.9275 9.183 10.815 9.7125C10.7055 10.2225 10.755 10.8165 10.884 11.4165C11.016 12.0255 11.241 12.6885 11.529 13.359C11.0683 14.5047 10.5363 15.6204 9.936 16.6995C9.16296 16.9427 8.41782 17.267 7.713 17.667C7.158 17.997 6.6645 18.387 6.3675 18.8475C6.0525 19.3365 5.955 19.9185 6.2475 20.502Z"
				fill="#fb7185"
			/>
		</svg>
	);
};

const Image = () => {
	return (
		<svg
			width="24"
			height="24"
			className="h-4 w-4"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M21 6.75V21C21 21.7956 20.6839 22.5587 20.1213 23.1213C19.5587 23.6839 18.7956 24 18 24H6C5.20435 24 4.44129 23.6839 3.87868 23.1213C3.31607 22.5587 3 21.7956 3 21V3C3 2.20435 3.31607 1.44129 3.87868 0.87868C4.44129 0.316071 5.20435 0 6 0L14.25 0L21 6.75ZM16.5 6.75C15.9033 6.75 15.331 6.51295 14.909 6.09099C14.4871 5.66903 14.25 5.09674 14.25 4.5V1.5H6C5.60218 1.5 5.22064 1.65804 4.93934 1.93934C4.65804 2.22064 4.5 2.60218 4.5 3V21C4.5 21.3978 4.65804 21.7794 4.93934 22.0607C5.22064 22.342 5.60218 22.5 6 22.5H18C18.3978 22.5 18.7794 22.342 19.0607 22.0607C19.342 21.7794 19.5 21.3978 19.5 21V6.75H16.5Z"
				fill="#999"
			/>
			<path
				d="M6.75 18.75C6.75 18.5511 6.82902 18.3603 6.96967 18.2197C7.11032 18.079 7.30109 18 7.5 18H12C12.1989 18 12.3897 18.079 12.5303 18.2197C12.671 18.3603 12.75 18.5511 12.75 18.75C12.75 18.9489 12.671 19.1397 12.5303 19.2803C12.3897 19.421 12.1989 19.5 12 19.5H7.5C7.30109 19.5 7.11032 19.421 6.96967 19.2803C6.82902 19.1397 6.75 18.9489 6.75 18.75ZM6.75 15.75C6.75 15.5511 6.82902 15.3603 6.96967 15.2197C7.11032 15.079 7.30109 15 7.5 15H16.5C16.6989 15 16.8897 15.079 17.0303 15.2197C17.171 15.3603 17.25 15.5511 17.25 15.75C17.25 15.9489 17.171 16.1397 17.0303 16.2803C16.8897 16.421 16.6989 16.5 16.5 16.5H7.5C7.30109 16.5 7.11032 16.421 6.96967 16.2803C6.82902 16.1397 6.75 15.9489 6.75 15.75ZM9.2085 10.188L11.2035 11.517L13.9845 8.7345C14.0402 8.67867 14.112 8.6418 14.1898 8.62915C14.2677 8.61649 14.3475 8.62869 14.418 8.664L17.25 10.125V12.75C17.25 12.9489 17.171 13.1397 17.0303 13.2803C16.8897 13.421 16.6989 13.5 16.5 13.5H7.5C7.30109 13.5 7.11032 13.421 6.96967 13.2803C6.82902 13.1397 6.75 12.9489 6.75 12.75V12C6.75 12 9.06 10.089 9.2085 10.188ZM9.375 9C9.67337 9 9.95952 8.88147 10.1705 8.6705C10.3815 8.45952 10.5 8.17337 10.5 7.875C10.5 7.57663 10.3815 7.29048 10.1705 7.0795C9.95952 6.86853 9.67337 6.75 9.375 6.75C9.07663 6.75 8.79048 6.86853 8.5795 7.0795C8.36853 7.29048 8.25 7.57663 8.25 7.875C8.25 8.17337 8.36853 8.45952 8.5795 8.6705C8.79048 8.88147 9.07663 9 9.375 9Z"
				fill="#999"
			/>
		</svg>
	);
};

const Text = () => {
	return (
		<svg
			width="24"
			height="24"
			className="h-4 w-4"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13.9395 0H6C5.20435 0 4.44129 0.316071 3.87868 0.87868C3.31607 1.44129 3 2.20435 3 3V21C3 21.7956 3.31607 22.5587 3.87868 23.1213C4.44129 23.6839 5.20435 24 6 24H18C18.7956 24 19.5587 23.6839 20.1213 23.1213C20.6839 22.5587 21 21.7956 21 21V7.0605C20.9999 6.66271 20.8418 6.28124 20.5605 6L15 0.4395C14.7188 0.158176 14.3373 8.49561e-05 13.9395 0V0ZM14.25 5.25V2.25L18.75 6.75H15.75C15.3522 6.75 14.9706 6.59196 14.6893 6.31066C14.408 6.02936 14.25 5.64782 14.25 5.25ZM7.5855 9H16.4145L16.5 12H15.75C15.48 10.356 15.216 10.212 13.209 10.1475L12.762 10.1325V17.7675C12.762 18.4725 12.912 18.6405 14.1165 18.75V19.5H9.885V18.75C11.0835 18.6405 11.232 18.474 11.232 17.769V10.1325L10.7925 10.1475C8.784 10.212 8.52 10.3575 8.25 12H7.5L7.5855 9Z"
				fill="#333"
			/>
		</svg>
	);
};

const Xlsx = () => {
	return (
		<svg
			width="24"
			height="24"
			className="h-4 w-4"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13.9395 0H6C5.20435 0 4.44129 0.316071 3.87868 0.87868C3.31607 1.44129 3 2.20435 3 3V21C3 21.7956 3.31607 22.5587 3.87868 23.1213C4.44129 23.6839 5.20435 24 6 24H18C18.7956 24 19.5587 23.6839 20.1213 23.1213C20.6839 22.5587 21 21.7956 21 21V7.0605C20.9999 6.66271 20.8418 6.28124 20.5605 6L15 0.4395C14.7188 0.158176 14.3373 8.49561e-05 13.9395 0V0ZM14.25 5.25V2.25L18.75 6.75H15.75C15.3522 6.75 14.9706 6.59196 14.6893 6.31066C14.408 6.02936 14.25 5.64782 14.25 5.25ZM8.826 10.02L12 13.8285L15.174 10.0185C15.3015 9.86574 15.4845 9.76988 15.6826 9.75202C15.8808 9.73415 16.078 9.79575 16.2308 9.92325C16.3835 10.0508 16.4794 10.2337 16.4972 10.4319C16.5151 10.6301 16.4535 10.8272 16.326 10.98L12.9765 15L16.326 19.02C16.4477 19.1734 16.5046 19.3683 16.4845 19.563C16.4645 19.7578 16.369 19.937 16.2186 20.0623C16.0682 20.1877 15.8747 20.2492 15.6795 20.2339C15.4844 20.2185 15.3029 20.1274 15.174 19.98L12 16.1715L8.826 19.9815C8.6985 20.1343 8.51553 20.2301 8.31735 20.248C8.11917 20.2658 7.92201 20.2043 7.76925 20.0767C7.61649 19.9492 7.52063 19.7663 7.50277 19.5681C7.4849 19.3699 7.5465 19.1728 7.674 19.02L11.0235 15L7.674 10.98C7.60815 10.9047 7.55802 10.8171 7.52656 10.7221C7.4951 10.6272 7.48294 10.5269 7.4908 10.4272C7.49865 10.3275 7.52637 10.2304 7.57232 10.1416C7.61826 10.0528 7.68151 9.97403 7.75834 9.91001C7.83517 9.84598 7.92402 9.79797 8.01968 9.7688C8.11533 9.73962 8.21586 9.72987 8.31534 9.74012C8.41482 9.75037 8.51124 9.78041 8.59894 9.82847C8.68664 9.87654 8.76384 9.94166 8.826 10.02Z"
				fill="#21A366"
			/>
		</svg>
	);
};

const File = () => {
	return (
		<svg
			width="24"
			height="24"
			className="h-4 w-4"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M8.25 10.5C8.05109 10.5 7.86032 10.579 7.71967 10.7197C7.57902 10.8603 7.5 11.0511 7.5 11.25C7.5 11.4489 7.57902 11.6397 7.71967 11.7803C7.86032 11.921 8.05109 12 8.25 12H15.75C15.9489 12 16.1397 11.921 16.2803 11.7803C16.421 11.6397 16.5 11.4489 16.5 11.25C16.5 11.0511 16.421 10.8603 16.2803 10.7197C16.1397 10.579 15.9489 10.5 15.75 10.5H8.25ZM7.5 14.25C7.5 14.0511 7.57902 13.8603 7.71967 13.7197C7.86032 13.579 8.05109 13.5 8.25 13.5H15.75C15.9489 13.5 16.1397 13.579 16.2803 13.7197C16.421 13.8603 16.5 14.0511 16.5 14.25C16.5 14.4489 16.421 14.6397 16.2803 14.7803C16.1397 14.921 15.9489 15 15.75 15H8.25C8.05109 15 7.86032 14.921 7.71967 14.7803C7.57902 14.6397 7.5 14.4489 7.5 14.25ZM7.5 17.25C7.5 17.0511 7.57902 16.8603 7.71967 16.7197C7.86032 16.579 8.05109 16.5 8.25 16.5H11.25C11.4489 16.5 11.6397 16.579 11.7803 16.7197C11.921 16.8603 12 17.0511 12 17.25C12 17.4489 11.921 17.6397 11.7803 17.7803C11.6397 17.921 11.4489 18 11.25 18H8.25C8.05109 18 7.86032 17.921 7.71967 17.7803C7.57902 17.6397 7.5 17.4489 7.5 17.25Z"
				fill="#aaa"
			/>
			<path
				d="M14.25 0H6C5.20435 0 4.44129 0.316071 3.87868 0.87868C3.31607 1.44129 3 2.20435 3 3V21C3 21.7956 3.31607 22.5587 3.87868 23.1213C4.44129 23.6839 5.20435 24 6 24H18C18.7956 24 19.5587 23.6839 20.1213 23.1213C20.6839 22.5587 21 21.7956 21 21V6.75L14.25 0ZM14.25 1.5V4.5C14.25 5.09674 14.4871 5.66903 14.909 6.09099C15.331 6.51295 15.9033 6.75 16.5 6.75H19.5V21C19.5 21.3978 19.342 21.7794 19.0607 22.0607C18.7794 22.342 18.3978 22.5 18 22.5H6C5.60218 22.5 5.22064 22.342 4.93934 22.0607C4.65804 21.7794 4.5 21.3978 4.5 21V3C4.5 2.60218 4.65804 2.22064 4.93934 1.93934C5.22064 1.65804 5.60218 1.5 6 1.5H14.25Z"
				fill="#aaa"
			/>
		</svg>
	);
};
