import { Button } from '@/components/button';
import { Card } from '@/components/card';
import { cn } from '@/lib/utils';
import { getAppVersion } from '@golee/react-native-web-bridge';
import { ChevronRightIcon } from 'lucide-react';
import { TProfile } from '../../../api/people/profiles';
import { EmptyList, LoadingLayout, Logout } from '../../../components';
import { GoToManager } from '../../../components/go-to-manager';
import { InvitesList } from '../../../components/invites/';
import { LayoutBase } from '../../../components/layout/layout';
import { usePermissions } from '../../../context/permissions/use-permissions';
import { useProfiles } from '../../../context/profiles/use-profiles';

export const Profiles = () => {
	const { identities } = usePermissions();

	const hasManagerRole = !!identities?.find(identity => identity.role !== 'organization_person');
	const hasOnlyManagerRole = identities?.every(identity => identity.role !== 'organization_person');

	if (hasOnlyManagerRole) {
		window.location.href = window._env_.REACT_APP_MANAGER_URL;
		return <LoadingLayout />;
	}

	return (
		<LayoutBase>
			<Card className="p-4">
				<div className={'flex flex-col gap-10'}>
					<ProfilesList />
					<InvitesList />
				</div>
			</Card>
			<div className="mt-4 flex flex-col items-center gap-4">
				{hasManagerRole && <GoToManager />}
				<Logout />
			</div>
			{getAppVersion() && (
				<div className={'absolute bottom-2 left-0 flex w-full justify-center text-neutral-500'}>
					<div>{getAppVersion()}</div>
				</div>
			)}
		</LayoutBase>
	);
};

const ProfilesList = () => {
	const { profiles } = useProfiles();

	if (!profiles || !profiles.length) {
		return <EmptyList />;
	}

	return (
		<div>
			<p className="mb-2 text-xs uppercase text-gray-400">{'Seleziona profilo'}</p>
			{profiles.map((profile, index) => (
				<div
					className={cn({
						'border-t border-solid border-muted': index > 0,
					})}
					key={profile._id}>
					<ProfileCard profile={profile} />
				</div>
			))}
		</div>
	);
};

const ProfileCard = ({ profile }: { profile: TProfile }) => {
	const { chooseProfile } = useProfiles();

	return (
		<div className="flex cursor-pointer items-center justify-between py-4" onClick={() => chooseProfile(profile)}>
			<div className="flex items-center gap-2">
				<p>
					{profile.name} {profile.surname}
				</p>
			</div>

			<Button variant="outline" size="icon">
				<ChevronRightIcon className="h-4 w-4" />
			</Button>
		</div>
	);
};
