import { Button } from '@/components/button';
import { Input } from '@/components/input';
import { Label } from '@/components/label';
import { useToast } from '@/components/toast';
import { useConan } from '@golee/gle-conan-tracker';
import { useState } from 'react';
import { apiClient } from '../../../../../api/client';
import LoadFileButton from '../../../../../components/uploader/document-uploader';
import { RequestDocument } from '../request/request-document';

export const createAndUploadDocument = async (formData: FormData) => {
	try {
		return await apiClient('documents').post<Document>(`org-person/documents/upload`, formData);
	} catch (err) {
		throw err;
	}
};

export type UploadOrRequestDocumentProps = {
	onUploadCompleted?: () => void;
	allowUpload?: boolean;
	allowRequest?: boolean;
};

export const UploadOrRequestDocument = (props: UploadOrRequestDocumentProps) => {
	const { trackEvent } = useConan();
	const { toast } = useToast();
	const [file, setFile] = useState<File>();
	const [fileName, setFileName] = useState<string>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const uploadFile = async () => {
		if (!file || !fileName) return;
		setIsLoading(true);

		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('name', fileName);

			await createAndUploadDocument(formData);

			props.onUploadCompleted?.();

			void trackEvent('family.document-uploaded', { file_name: file.name });
		} catch (e: any) {
			toast({
				variant: 'destructive',
				title: 'Errore',
				description: 'Si è verificato un errore imprevisto, riprova più tardi',
			});
		} finally {
			setIsLoading(false);
			setFile(undefined);
		}
	};

	return (
		<div className={'mb-4'}>
			{file && fileName ? (
				<div className={'flex items-end gap-2'}>
					<div className="w-full space-y-1">
						<Label>Imposta nome documento</Label>
						<Input value={fileName} onChange={e => setFileName(e.target.value)} />
					</div>

					<Button onClick={uploadFile} isLoading={isLoading} className="h-10">
						Invia documento
					</Button>
				</div>
			) : (
				<div className={'flex gap-4'}>
					{props.allowUpload && (
						<LoadFileButton
							onFileLoaded={file => {
								setFile(file);
								setFileName(file.name);
							}}
						/>
					)}
					{props.allowRequest && <RequestDocument />}
				</div>
			)}
		</div>
	);
};
