import { Button } from '@/components/button';
import { Heading } from '@/components/heading';
import { Spinner } from '@/components/spinner';
import { useI18n } from '@gle/providers.i18n';
import { TObjTranche } from '@golee/gle-types';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { formatAsString } from '../../../../@/utils/lang-utility';
import { getTranche } from '../../../../api/financial/tranches';
import { Currency, getTrancheReasonLabel, NavigateBack, PaymentStatusBadge } from '../../../../components';
import { Divider } from '../../../../components/divider';
import { useOrgPerson } from '../../../../context/org-person';
import { useSeason } from '../../../../context/season';
import { useSettings } from '../../../../context/settings';
import { usePaymentInfo } from '../../../../hooks/use-payment-info';
import { PaymentButton } from '../../../public/pay/payment-button';
import { PaymentAttachments } from './attachments';
import { DownloadInvoiceButton } from './invoice';
import { DownloadReceiptButton } from './receipt';

export const PaymentDetail = () => {
	const { state } = useLocation();
	const { paymentId: paymentIdFromUri } = useParams();
	const { selectedSeason } = useSeason();

	const [isLoading, setIsLoading] = useState<boolean>();
	const [payment, setPayment] = useState<TObjTranche>();

	const loadData = async () => {
		if (state) {
			const paymentFromState = (state as any).payment as TObjTranche;
			if (paymentFromState) {
				setPayment(paymentFromState);
				return;
			}
		}

		setIsLoading(true);

		if (!paymentIdFromUri) return;

		try {
			const { data } = await getTranche({ id: paymentIdFromUri, season: selectedSeason });
			setPayment(data);
		} catch (err) {
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		loadData();
	}, []);

	if (isLoading) {
		return (
			<div className="mt-8 flex justify-center">
				<Spinner />
			</div>
		);
	}

	if (!payment) {
		return (
			<div>
				<NavigateBack to={'/payments'} className="mb-4" />
				<div>
					<Heading level={3} className="mt-2">
						Si è verificato un errore
					</Heading>
					<p>Questo pagamento non è più disponibile.</p>
				</div>
			</div>
		);
	}

	return <Inner payment={payment} />;
};

const Inner = (props: { payment: TObjTranche }) => {
	const { messages } = useI18n();

	return (
		<div>
			<NavigateBack to={'/payments'} className="mb-4" />

			<Heading level={2} className="mb-4">
				{getTrancheReasonLabel(messages, props.payment)}
			</Heading>

			<div className="mb-4">
				<PaymentStatusBadge payment={props.payment} />
			</div>

			<div>
				<Heading className="text-4xl">
					<Currency value={props.payment.amount.total_amount} />
				</Heading>
			</div>

			{props.payment.type === 'ENT' ? (
				<>
					{props.payment.cashed ? (
						<PaymentCashedDetail payment={props.payment} />
					) : (
						<PaymentNotCashedDetail payment={props.payment} />
					)}
				</>
			) : (
				<PaymentAttachments paymentId={props.payment._id} />
			)}
		</div>
	);
};

const PaymentCashedDetail = (props: { payment: TObjTranche }) => {
	const { messages } = useI18n();
	const { settings } = useSettings();

	return (
		<div>
			<div className="mt-4 flex justify-center">
				{props.payment.invoice?.id && <DownloadInvoiceButton invoiceId={props.payment.invoice.id} />}
				{props.payment.receipt?.id && <DownloadReceiptButton receiptId={props.payment.receipt?.id} />}
			</div>

			<div className="mt-4 border-t border-solid border-muted pt-4">
				<Heading level={3} className="mb-2">
					Dettagli
				</Heading>
				<div className="text-sm">
					<p>
						{props.payment.type === 'ENT'
							? `Pagamento effettuato il: ${props.payment.cashed_date}.`
							: `Pagata effettuato dalla società il: ${props.payment.cashed_date}.`}
					</p>

					<p>Metodo di pagamento: {messages[`payment_method.${props.payment.payment_method}`]}.</p>
					{settings?.arePaymentsNotesVisible && props.payment.notes && (
						<p>Nota del pagamento: {props.payment.notes}</p>
					)}
				</div>
			</div>

			<PaymentAttachments paymentId={props.payment._id} />
		</div>
	);
};

const PaymentNotCashedDetail = (props: { payment: TObjTranche }) => {
	const { messages } = useI18n();
	const { orgPerson } = useOrgPerson();
	const { paymentInfo, loadPaymentInfo, isLoading } = usePaymentInfo();
	const { settings } = useSettings();

	useEffect(() => {
		if (orgPerson.organizationId && props.payment._id) {
			void loadPaymentInfo(orgPerson.organizationId, props.payment._id);
		}
	}, []);

	if (isLoading) {
		return (
			<div className="mt-8 flex justify-center">
				<Spinner />
			</div>
		);
	}

	const canPay =
		props.payment.amount.total_amount > 0 && paymentInfo && paymentInfo.availablePaymentMethods.length > 0;

	return (
		<div>
			{canPay ? (
				<PaymentButton
					organizationId={orgPerson.organizationId}
					trancheId={props.payment._id}
					availablePaymentMethods={paymentInfo.availablePaymentMethods}
				/>
			) : (
				<div className="mt-4 flex justify-center">
					<Button disabled variant={'secondary'} size="lg">
						Pagamento in app non disponibile
					</Button>
				</div>
			)}

			<div className="mt-4 border-t border-solid border-muted pt-4">
				<Heading level={3} className="mb-2">
					Dettagli
				</Heading>
				<div className="text-sm">
					{!!props.payment.expected_cashed_date && (
						<p>
							{formatAsString(messages['to_be_paid_within_date'], {
								date: props.payment.expected_cashed_date,
							})}
						</p>
					)}
					{settings?.arePaymentsNotesVisible && props.payment.notes && (
						<p>Nota del pagamento: {props.payment.notes}</p>
					)}
					{canPay && (
						<>
							<Divider />
							<p>Puoi effettuare il pagamento utilizzando uno dei metodi disponibili.</p>
							<p>
								Il pagamento verrà ricevuto automaticamente dalla società che potrà inviarti la
								ricevuta.
							</p>
							<p className="mt-3 border-t border-solid border-muted pt-3 text-xs text-muted-foreground">
								<strong>Note</strong>: Al pagamento potrebbe essere applicata una commissione. Le
								commissioni possono variare in base alle impostazioni che ha configurato la società ed
								al metodo di pagamento selezionato. Naviga alla schermata successiva di pagamento per
								visualizzare il riepilogo dell'importo, delle commissioni e altri dettagli.
							</p>
						</>
					)}
				</div>
			</div>

			<PaymentAttachments paymentId={props.payment._id} />
		</div>
	);
};
