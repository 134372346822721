import { Button } from '@/components/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/command';
import { FormItem, FormLabel, FormMessage } from '@/components/form';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/popover';
import { cn } from '@/lib/utils';
import axios from 'axios';
import { CheckIcon, ChevronDownIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { useDebounce } from '../../hooks/use-debounce';

type Town = {
	name: string;
	province: string;
	code: string | null;
};

export const getItalianTowns = async (skip: number, limit: number, search: string) => {
	const URL = 'https://europe-west8-golee-infra.cloudfunctions.net/italian-towns-fetcher';

	try {
		const { data: towns } = await axios.get<Town[]>(URL, {
			params: {
				skip,
				limit,
				search,
			},
		});

		const hasMore = limit === towns.length;

		if (!search || search.toLowerCase().includes('est')) {
			if (skip === 0) {
				towns.unshift({
					name: 'Estero',
					code: null,
					province: 'EE',
				});
			}
		}

		return {
			options: towns.map(town => ({
				// fuck ourselves and towns with same names.
				value: `${town.name}-${town.province}`,
				label: `${town.name} (${town.province})`,
				province: town.province,
			})),
			hasMore: hasMore,
			additional: { start: skip + limit, limit: limit },
		};
	} catch (error) {
		console.error('Error fetching Italian towns:', error);
		throw error;
	}
};

type TownsSelectFieldProps = {
	formField: ControllerRenderProps<any, any>;
	label: string;
};

export const TownsSelectField = ({ formField, ...props }: TownsSelectFieldProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const [towns, setTowns] = useState<{ label: string; value: string; province: string }[]>([]);

	const [searchTerm, setSearchTerm] = useState('');
	const debouncedSearchTerm = useDebounce(searchTerm, 300);

	const fetchTowns = async (query: string) => {
		if (!query) {
			setTowns([]);
			return;
		}

		try {
			const data = await getItalianTowns(0, 50, query);
			setTowns(data.options);
		} catch (error) {
			console.error('Error fetching towns:', error);
			setTowns([]);
		}
	};

	useEffect(() => {
		fetchTowns(debouncedSearchTerm);
	}, [debouncedSearchTerm]);

	const getLabel = () => {
		if (!formField.value) {
			return 'Comune non impostato';
		}

		const town = towns.find(town => town.value === formField.value);

		if (town) {
			return town.label;
		}

		return formField.value;
	};

	return (
		<FormItem>
			<FormLabel>{props.label}</FormLabel>
			<Popover open={isOpen} onOpenChange={setIsOpen}>
				<PopoverTrigger asChild>
					<Button
						variant="outline"
						role="combobox"
						aria-expanded={isOpen}
						className={cn(
							'flex h-10 w-full justify-between border-input px-3 font-normal active:scale-100',
							{ 'text-muted-foreground': !formField.value }
						)}>
						{getLabel()}
						<ChevronDownIcon className="h-4 w-4 opacity-50" />
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-[300px] p-0">
					<Command>
						<CommandInput placeholder="Cerca comune..." onValueChange={val => setSearchTerm(val)} />
						<CommandList>
							<CommandEmpty>Nessun comune trovato</CommandEmpty>
							<CommandGroup>
								{towns.map(town => (
									<CommandItem
										key={town.value}
										value={town.value}
										onSelect={currentValue => {
											formField.onChange(currentValue === formField.value ? '' : currentValue);
											setIsOpen(false);
										}}>
										{town.label}
										<CheckIcon
											className={cn(
												'ml-auto',
												formField.value === town.value ? 'opacity-100' : 'opacity-0'
											)}
										/>
									</CommandItem>
								))}
							</CommandGroup>
						</CommandList>
					</Command>
				</PopoverContent>
			</Popover>
			<FormMessage />
		</FormItem>
	);
};
