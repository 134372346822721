import { Card, CardContent } from '@/components/card';
import { cn } from '@/lib/utils';
import { AlertCircleIcon, CheckCircleIcon } from 'lucide-react';
import { useTranches } from '../../../../../context/tranches';
import { ProfileCardCallToAction, ProfileCardSectionTitle } from '../../components';

export const PaymentsCard = () => {
	const { tranches } = useTranches();

	if (!tranches.length) return <></>;

	const paid = tranches
		.filter(t => t.cashed && t.type === 'ENT')
		.map(t => t.amount.total_amount)
		.reduce((t1, t2) => t1 + t2, 0);

	const total = tranches
		.filter(tranche => tranche.type === 'ENT')
		.map(t => t.amount.total_amount)
		.reduce((t1, t2) => t1 + t2, 0);

	const diff = total - paid;

	return (
		<div>
			<ProfileCardSectionTitle>Stato dei pagamenti</ProfileCardSectionTitle>
			<Card className="overflow-hidden">
				<CardContent className="flex flex-col p-0">
					<div
						className={cn('flex items-center gap-2 border-b border-solid border-muted px-2 py-3', {
							'bg-emerald-50 text-emerald-800 dark:bg-emerald-600 dark:text-emerald-50': diff <= 0,
							'bg-amber-100 text-amber-800 dark:bg-amber-600 dark:text-amber-50': diff > 0,
						})}>
						{diff > 0 ? (
							<AlertCircleIcon className="h-4 w-4 text-amber-500 dark:text-amber-100" />
						) : (
							<CheckCircleIcon className="h-4 w-4 text-emerald-500 dark:text-emerald-100" />
						)}
						<span className="flex w-full items-center justify-between font-medium">
							<span>{`€ ${paid} di € ${total}`}</span>
							<span className="text-sm">{diff > 0 ? 'Da pagare' : 'Pagato'}</span>
						</span>
					</div>

					<ProfileCardCallToAction to={'/payments'} label={'Vai ai pagamenti'} />
				</CardContent>
			</Card>
		</div>
	);
};
