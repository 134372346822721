import { Button } from '@/components/button';
import { Form, FormSubmitButtons } from '@/components/form';
import { ReadOnlyValue } from '@/components/read-only-input';
import { useToast } from '@/components/toast';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { setMetadata } from '../../../../../api/people/metadata';
import {
	CustomInputFields,
	ReadOnlyCustomInputFields,
} from '../../../../../components/custom-fields/custom-input-fields';
import { OrgPersonAthleteMetadata, OrgPersonMetadata } from '../../../../../context/org-person';
import { useCustomFields } from '../../../../../hooks/custom-input-fields';
import { CustomField } from '../../../../../hooks/custom-input-fields/types';

const createDeepSchema = <T extends object>(obj: T): z.ZodType<T> => {
	const schema = z.object(
		Object.keys(obj as object).reduce((acc, key) => {
			const value = (obj as any)[key];
			if (value && typeof value === 'object' && !Array.isArray(value)) {
				acc[key] = createDeepSchema(value).optional();
			} else if (Array.isArray(value)) {
				acc[key] = z.array(z.any()).optional();
			} else {
				acc[key] = z.any().optional();
			}
			return acc;
		}, {} as any)
	);

	return schema as unknown as z.ZodType<T>;
};

const formSchema = z.object({
	groups: z.array(z.object({ groupName: z.string() })),
	person: createDeepSchema({} as OrgPersonMetadata),
	athlete: createDeepSchema({} as OrgPersonAthleteMetadata),
});

type SportsFormProps = {
	orgPersonId: string;
	season: string;
	initialValues: z.infer<typeof formSchema>;
	onComplete: () => void;
	onCancel: () => void;
};

export const SportsForm = (props: SportsFormProps) => {
	const { toast } = useToast();

	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [isSaving, setIsSaving] = useState<boolean>(false);

	const { customFields } = useCustomFields();

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: props.initialValues,
	});

	const onSubmit = async () => {
		setIsSaving(true);

		const values = form.getValues();

		try {
			await setMetadata(
				{ orgPersonId: props.orgPersonId, season: props.season },
				{
					person: values.person,
					athlete: values.athlete,
				}
			);

			toast({
				title: 'Salvataggio completato',
				description: 'I dati sono stati aggiornati con successo',
				variant: 'success',
			});

			props.onComplete();
		} catch (error) {
			toast({
				variant: 'destructive',
				title: 'Errore',
				description: 'Si è verificato un errore imprevisto, riprova più tardi',
			});
		} finally {
			setIsSaving(false);
		}
	};

	if (!isEdit) {
		return (
			<ReadOnlySportsForm
				customFields={customFields}
				initialValues={props.initialValues}
				onEdit={() => {
					setIsEdit(true);
				}}
			/>
		);
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="mx-auto flex flex-col gap-4">
				<CustomInputFields fields={customFields} form={form} />

				<FormSubmitButtons
					isSaving={isSaving}
					onCancel={() => {
						setIsEdit(false);
					}}
				/>
			</form>
		</Form>
	);
};

type ReadOnlySportsFormProps = Pick<SportsFormProps, 'initialValues'> & {
	onEdit: () => void;
	customFields: CustomField[];
};

const ReadOnlySportsForm = (props: ReadOnlySportsFormProps) => {
	const { customFields } = useCustomFields();

	return (
		<div className="flex flex-col gap-4">
			<ReadOnlyValue
				value={props.initialValues.groups.map(group => group.groupName).join(', ') || '-'}
				type="text"
				label="Gruppi"
			/>

			<ReadOnlyValue
				type="text"
				label="Matricola"
				value={props.initialValues.athlete?.technicalProfile?.registrationNumber || '-'}
			/>

			<ReadOnlyValue
				type="date"
				label="Data primo tesseramento"
				value={props.initialValues.person.dates?.athleteMembershipFirstDate}
			/>

			<ReadOnlyValue
				type="date"
				label="Data scadenza tesseramento"
				value={props.initialValues.person.dates?.athleteMembershipExpirationDate}
			/>

			<ReadOnlyCustomInputFields values={props.initialValues} fields={customFields} />

			<div className="flex justify-end">
				<Button variant="secondary" onClick={props.onEdit} type="button">
					Modifica dati
				</Button>
			</div>
		</div>
	);
};
