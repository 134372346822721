import { HTMLAttributes } from 'react';

export const Divider: React.FC<Omit<HTMLAttributes<HTMLDivElement>, 'children'>> = ({ style, ...props }) => {
	return (
		<div className={'flex items-center'} style={{ height: 40 }}>
			<div className={'flex-grow border-t border-muted'} style={{ ...style }} {...props} />
			{props.title && <p className={'pl-2 pr-2 text-muted-foreground'}>{props.title}</p>}
			<div className={'flex-grow border-t border-muted'} style={{ ...style }} {...props} />
		</div>
	);
};
