import { Card, CardContent } from '@/components/card';
import { cn } from '@/lib/utils';
import { formatDate } from '@/utils';
import { AlertCircleIcon, AlertOctagonIcon, BanIcon, CheckCircleIcon } from 'lucide-react';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import { ProfileCardCallToAction, ProfileCardSectionTitle } from '../../components';

type MedicalCardProps = {
	expirationDate?: string;
};

type MedicalExaminationData = {
	status: 'unset' | 'expired' | 'valid' | 'expiring';
	description: string;
	icon: ReactNode;
};

const getMedicalExaminationData = (date?: string): MedicalExaminationData => {
	if (!date) {
		return {
			status: 'unset',
			description: 'Non impostata',
			icon: <BanIcon className="h-4 w-4 text-muted-foreground" />,
		};
	}

	const daysDiff = DateTime.fromISO(date).diff(DateTime.now(), 'days').days;

	let description: string;
	let status: 'expired' | 'valid' | 'expiring';
	let icon: ReactNode;

	if (daysDiff <= 0) {
		description = 'Scaduta';
		status = 'expired';
		icon = <AlertOctagonIcon className="h-4 w-4 text-destructive-foreground" />;
	} else if (daysDiff > 30) {
		description = 'Valida';
		status = 'valid';
		icon = <CheckCircleIcon className="h-4 w-4 text-emerald-500 dark:text-emerald-100" />;
	} else {
		description = 'In Scadenza';
		status = 'expiring';
		icon = <AlertCircleIcon className="h-4 w-4 text-amber-500 dark:text-amber-100" />;
	}

	return { status, description, icon };
};

export const MedicalCard = (props: MedicalCardProps) => {
	const { status, description, icon } = getMedicalExaminationData(props.expirationDate);

	return (
		<div>
			<ProfileCardSectionTitle>Visita medica</ProfileCardSectionTitle>
			<Card className="overflow-hidden">
				<CardContent className="over flex flex-col p-0">
					<div
						className={cn('flex items-center gap-2 px-2 py-3', {
							'bg-accent text-accent-foreground': status === 'unset',
							'bg-destructive text-destructive-foreground': status === 'expired',
							'bg-emerald-50 text-emerald-800 dark:bg-emerald-600 dark:text-emerald-50':
								status === 'valid',
							'bg-amber-100 text-amber-800 dark:bg-amber-600 dark:text-amber-50': status === 'expiring',
						})}>
						{icon}
						<span className="flex w-full items-center justify-between font-medium">
							<span className="capitalize">
								{props.expirationDate ? formatDate(props.expirationDate) : 'Non impostata'}
							</span>
							{status !== 'unset' && <span className="text-sm">{description}</span>}
						</span>
					</div>
					<ProfileCardCallToAction to={'medical'} label={'Apri profilo sanitario completo'} />
				</CardContent>
			</Card>
		</div>
	);
};
