import { Button } from '@/components/button';
import { isAppVersionGte, isFromApp, sendMessageToReactNative } from '@golee/react-native-web-bridge';
import { ChevronRightIcon } from 'lucide-react';

export const GoToManager = () => {
	const onClick = () => {
		if (!isFromApp()) {
			window.location.href = window._env_.REACT_APP_MANAGER_URL;
			return;
		}

		if (isAppVersionGte('2.0.0')) {
			window.location.href = window._env_.REACT_APP_MANAGER_URL;
			return;
		}

		void sendMessageToReactNative('change-app', { appName: 'Manager' });
	};

	return (
		<Button variant={'secondary'} onClick={onClick}>
			{'Accedi al gestionale'} <ChevronRightIcon size={18} />
		</Button>
	);
};
