import { Card, CardContent } from '@/components/card';
import { cn } from '@/lib/utils';
import { Divider } from '../../../../components/divider';
import { useReadableSeason } from '../../../../components/season/readable-season';
import { CompleteMembershipCard } from '../types';

type MembershipCardProps = {
	data: CompleteMembershipCard;
	isDisabled?: boolean;
};

export const MembershipCard = (props: MembershipCardProps) => {
	const { data, isDisabled } = props;

	if (!data) {
		return null;
	}

	const width = 300;
	const height = 391;

	return (
		<Card
			className={cn('relative flex flex-col overflow-hidden rounded-xl p-4 shadow-sm', {
				'bg-background': !isDisabled,
				'opacity-50 grayscale': isDisabled,
			})}
			style={{ width: `${width}px`, height: `${height}px` }}>
			<CardContent className="flex h-full w-full flex-col p-0">
				<OrganizationMainDataSection data={data.organization} isDisabled={isDisabled} />
				<Divider />
				<MemberMainDataSection data={data.member} />
				<Divider />
				<MemberCustomDataSection data={data.member} />
				<GoleeLogoSection />
			</CardContent>
		</Card>
	);
};

const OrganizationMainDataSection = (props: { data: CompleteMembershipCard['organization']; isDisabled?: boolean }) => {
	const readableSeason = useReadableSeason();

	return (
		<div>
			<div className="absolute right-0 top-0 h-[100px] w-[100px] overflow-hidden">
				<div className="absolute right-0 top-0 h-[200%] w-[200%]">
					{props.data.tertiaryColor?.enabled && (
						<div
							className={`absolute right-[-85px] top-[0px] h-[30px] w-[170px] rotate-45`}
							style={{ background: props.data.tertiaryColor?.value }}></div>
					)}
					{props.data.secondaryColor?.enabled && (
						<div
							className={`absolute right-[-85px] top-[30px] z-20 h-[14px] w-[170px] rotate-45`}
							style={{ background: props.data.secondaryColor?.value }}></div>
					)}
					{props.data.primaryColor?.enabled && (
						<div
							className={`absolute right-[-85px] top-[40px] z-10 h-[25px] w-[170px] rotate-45`}
							style={{ background: props.data.primaryColor?.value }}></div>
					)}
				</div>
			</div>

			<div className="flex items-center justify-start gap-2">
				<img src={props.data.logo.value as string} alt={props.data.name.value as string} className="h-6 w-6" />
				<div className="w-[75%]">
					<p className="text-[11px] font-semibold">{props.data.name.value}</p>
					{props.data.season.enabled && (
						<p className="text-[9px] font-medium text-muted-foreground">
							Stagione {readableSeason(props.data.season.value as string)}
						</p>
					)}
				</div>
			</div>
		</div>
	);
};

const MemberMainDataSection = (props: { data: CompleteMembershipCard['member'] }) => {
	return (
		<div className="mt-2 flex w-full items-center gap-2">
			{props.data.photo.enabled && props.data.photo.value && (
				<div className="h-[60px] w-[60px] overflow-hidden rounded-lg border border-solid border-muted">
					<img
						className={'h-[60px] w-[60px] object-cover'}
						src={props.data.photo.value as string}
						alt={props.data.denomination.value as string}
					/>
				</div>
			)}
			<div>
				<p className="text-[11px] font-semibold">{props.data.denomination.value as string}</p>
				{props.data.fiscalCode.enabled && (
					<p className="text-[9px] text-muted-foreground">{props.data.fiscalCode.value as string}</p>
				)}
				{props.data.birthdate?.enabled && (
					<p className="text-[9px] text-muted-foreground">{props.data.birthdate.value as string}</p>
				)}
			</div>
		</div>
	);
};

const MemberCustomDataSection = (props: { data: CompleteMembershipCard['member'] }) => {
	const customDataSectionFields = new Set(['medicalExamination', 'registrationNumber', 'groups']);

	return (
		<div className="mt-2 flex w-full flex-col flex-wrap justify-between gap-1">
			{Object.values(props.data)
				.filter(field => field.enabled)
				.filter(field => customDataSectionFields.has(field.key))
				.map(field => (
					<div key={field.key}>
						<p className="text-[9px] text-muted-foreground">{field.label}</p>
						<p className="text-[9px]">{field.value || '-'}</p>
					</div>
				))}
		</div>
	);
};

const GoleeLogoSection = () => {
	return (
		<div className={'flex h-full w-full flex-col items-center justify-end'}>
			<svg viewBox="0 0 203 90" fill="#fa6762" style={{ width: 30 }}>
				<path d="M41.6 60V20H32V25.04C29.04 21.36 24.8 19.2 20 19.2C9.04 19.2 0.8 27.76 0.8 40C0.8 52.24 9.04 60.8 20 60.8C24.8 60.8 29.04 58.64 32 54.96V60C32 67.04 28.16 72 21.6 72C16.08 72 12.16 68.48 11.28 63.2H1.6C3.04 73.68 10.96 80.8 21.6 80.8C32.64 80.8 41.6 72.24 41.6 60ZM21.2 52C15.04 52 10.4 47.2 10.4 40C10.4 32.8 15.04 28 21.2 28C27.36 28 32 32.8 32 40C32 47.2 27.36 52 21.2 52ZM70.4188 60.8C82.0988 60.8 90.8188 52.24 90.8188 40C90.8188 27.76 82.0988 19.2 70.4188 19.2C58.7388 19.2 50.0188 27.76 50.0188 40C50.0188 52.24 58.7388 60.8 70.4188 60.8ZM70.4188 52C64.2588 52 59.6188 47.2 59.6188 40C59.6188 32.8 64.2588 28 70.4188 28C76.5788 28 81.2188 32.8 81.2188 40C81.2188 47.2 76.5788 52 70.4188 52ZM108.825 60V-4.76837e-06H99.225V60H108.825ZM156.886 40C156.886 27.76 148.486 19.2 137.046 19.2C125.606 19.2 117.206 27.76 117.206 40C117.206 52.24 125.606 60.8 137.046 60.8C146.486 60.8 153.446 56.32 155.846 48H145.446C144.166 50.8 141.686 52.4 137.446 52.4C131.526 52.4 127.686 49.04 126.646 43.2H156.726C156.806 42.16 156.886 41.12 156.886 40ZM137.046 27.6C142.646 27.6 146.326 30.8 147.366 36.4H126.726C127.766 30.8 131.446 27.6 137.046 27.6ZM202.199 40C202.199 27.76 193.799 19.2 182.359 19.2C170.919 19.2 162.519 27.76 162.519 40C162.519 52.24 170.919 60.8 182.359 60.8C191.799 60.8 198.759 56.32 201.159 48H190.759C189.479 50.8 186.999 52.4 182.759 52.4C176.839 52.4 172.999 49.04 171.959 43.2H202.039C202.119 42.16 202.199 41.12 202.199 40ZM182.359 27.6C187.959 27.6 191.639 30.8 192.679 36.4H172.039C173.079 30.8 176.759 27.6 182.359 27.6Z"></path>
			</svg>
		</div>
	);
};
