import { Button } from '@/components/button';
import { useToast } from '@/components/toast';
import { useState } from 'react';
import { AvailablePaymentMethod, getCheckoutLink } from '../../../api/payments/link';

const useCheckout = (organizationId: string, trancheId: string) => {
	const { toast } = useToast();
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<AvailablePaymentMethod>();
	const [isLoadingLink, setIsLoadingLink] = useState<boolean>(false);
	const [checkoutLink, setCheckoutLink] = useState<string>();

	const onPaymentMethodChange = async (paymentMethod: AvailablePaymentMethod) => {
		if (isLoadingLink) return;

		setIsLoadingLink(true);
		setSelectedPaymentMethod(paymentMethod);

		try {
			const { url } = await getCheckoutLink({
				organizationId: organizationId,
				trancheId: trancheId,
				paymentMethod,
			});

			setCheckoutLink(url);
		} catch (err) {
			toast({
				variant: 'destructive',
				title: 'Errore',
				description: 'Si è verificato un errore imprevisto, riprova più tardi',
			});
		} finally {
			setIsLoadingLink(false);
		}
	};

	return {
		selectedPaymentMethod,
		onPaymentMethodChange,
		checkoutLink,
		isLoadingLink,
	};
};

export const PaymentButton = (props: {
	organizationId: string;
	trancheId: string;
	availablePaymentMethods: AvailablePaymentMethod[];
}) => {
	const { onPaymentMethodChange, selectedPaymentMethod, checkoutLink, isLoadingLink } = useCheckout(
		props.organizationId,
		props.trancheId
	);

	if (!props.availablePaymentMethods.length) {
		return (
			<div className="mt-4 rounded bg-amber-100 p-2 text-amber-900">
				<p className="text-sm">Nessun metodo di pagamento disponibile al momento, riprova più tardi.</p>
			</div>
		);
	}

	return (
		<div>
			<PaymentMethodSelector
				paymentMethods={props.availablePaymentMethods}
				selected={selectedPaymentMethod}
				onChange={onPaymentMethodChange}
			/>

			<div className="mb-4 flex justify-center">
				<a href={checkoutLink} className="block">
					<Button isLoading={isLoadingLink} disabled={!checkoutLink || isLoadingLink} className="text-base">
						{!checkoutLink
							? 'Seleziona metodo di pagamento'
							: isLoadingLink
								? 'Attendi'
								: 'Procedi al pagamento'}
					</Button>
				</a>
			</div>
		</div>
	);
};

export const PaymentMethodSelector = (props: {
	paymentMethods: AvailablePaymentMethod[];
	selected?: AvailablePaymentMethod;
	onChange: (paymentMethod: AvailablePaymentMethod) => void;
}) => {
	return (
		<div className="my-4">
			<h3 className="mb-4">Seleziona un metodo di pagamento</h3>

			<div className="flex items-center justify-around gap-4">
				{props.paymentMethods.map((paymentMethod, index) => (
					<PaymentMethodCard
						key={`pm-${index}`}
						paymentMethod={paymentMethod}
						isActive={props.selected === paymentMethod}
						onClick={() => props.onChange(paymentMethod)}
					/>
				))}
			</div>
		</div>
	);
};

const PaymentMethodCard = (props: {
	paymentMethod: AvailablePaymentMethod;
	isActive?: boolean;
	onClick: () => void;
}) => {
	switch (props.paymentMethod) {
		case 'card': {
			return (
				<div
					onClick={props.onClick}
					className={`flex h-24 w-full max-w-[200px] cursor-pointer flex-col items-center justify-center gap-2 rounded-md border border-solid p-2 hover:bg-accent ${
						props.isActive ? 'bg-accent' : ''
					}`}>
					<img
						alt="sepa"
						src={`${window._env_.PUBLIC_URL}images/visa-mastercard.png`}
						style={{ width: 70 }}
					/>
					<p className="text-center font-medium">Carta</p>
				</div>
			);
		}
		case 'sepa_debit': {
			return (
				<div
					onClick={props.onClick}
					className={`flex h-24 w-full max-w-[200px] cursor-pointer flex-col items-center justify-center gap-2 rounded-md border border-solid p-2 hover:bg-accent ${
						props.isActive ? 'bg-accent' : ''
					}`}>
					<p className="text-center font-extrabold text-blue-700 dark:text-blue-300">
						S<span className="text-yellow-400 dark:text-yellow-200">€</span>PA
					</p>
					<p className="text-center font-medium">Bonifico</p>
				</div>
			);
		}
		case 'klarna': {
			return (
				<div
					onClick={props.onClick}
					className={`flex h-24 w-full max-w-[200px] cursor-pointer flex-col items-center justify-center gap-2 rounded-md border border-solid p-2 hover:bg-accent ${
						props.isActive ? 'bg-accent' : ''
					}`}>
					<img
						alt="klarna"
						src={`${window._env_.PUBLIC_URL}images/klarna.png`}
						style={{ width: 80 }}
						className="dark:invert"
					/>
					<p className="text-center font-medium">In 3 rate</p>
				</div>
			);
		}
		default: {
			return <></>;
		}
	}
};
