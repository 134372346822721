import { Card, CardContent } from '@/components/card';
import { useI18n } from '@gle/providers.i18n';
import { BanIcon, ChevronRightIcon, PlusIcon, User2Icon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { OrgPerson, Parent } from '../../../../../context/org-person';
import { useSettings } from '../../../../../context/settings';
import { ProfileCardCallToAction, ProfileCardSectionTitle } from '../../components';

type ParentsCardProps = {
	parents?: OrgPerson['parents'];
};

export const ParentsCard = (props: ParentsCardProps) => {
	const { settings } = useSettings();

	return (
		<div>
			<ProfileCardSectionTitle>Responsabili legali</ProfileCardSectionTitle>
			<Card className="overflow-hidden">
				<CardContent className="over flex flex-col p-0">
					{!props.parents?.length && (
						<div className="flex items-center gap-2 border-b border-solid border-muted px-2 py-3 text-sm text-muted-foreground">
							<span>
								<BanIcon className="h-4 w-4 text-muted-foreground" />
							</span>
							<span>Nessun responsabile legale impostato</span>
						</div>
					)}

					{props.parents?.map(parent => <ParentRender parent={parent} key={parent._id} />)}

					{settings.allowParentsEdit && (
						<ProfileCardCallToAction
							to={'parents'}
							label={'Aggiungi'}
							className="border-t-0"
							icon={<PlusIcon className="h-4 w-4 text-muted-foreground" />}
						/>
					)}
				</CardContent>
			</Card>
		</div>
	);
};

const ParentRender = ({ parent }: { parent: Parent }) => {
	const { settings } = useSettings();

	if (!settings.allowParentsEdit) {
		return (
			<div className="flex items-center gap-2 border-b border-solid border-muted px-2 py-3">
				<ParentItem parent={parent} />
			</div>
		);
	}

	return (
		<Link
			className="flex items-center gap-2 border-b border-solid border-muted px-2 py-3 hover:bg-accent"
			to={'parents'}
			state={{ parent }}>
			<ParentItem parent={parent} showIcon />
		</Link>
	);
};

const ParentItem = ({ parent, ...props }: { parent: Parent; showIcon?: boolean }) => {
	const { messages } = useI18n();

	return (
		<>
			<span>
				<User2Icon className="h-4 w-4 text-muted-foreground" />
			</span>
			<span className="flex w-full items-center justify-between">
				<span>
					{parent.surname} {parent.name}{' '}
					{parent.kinship && (
						<span className="text-xs text-muted-foreground">({messages[`kinship.${parent.kinship}`]})</span>
					)}
				</span>

				{props.showIcon && (
					<span>
						<ChevronRightIcon className="h-4 w-4 text-muted-foreground" />
					</span>
				)}
			</span>
		</>
	);
};
