import axios from 'axios';
import { ApiLocalConfig, ApiService } from './types';

export const uniqueAxiosInstance = axios.create({
	headers: {
		'Content-Type': 'application/json',
	},
});

const loadLocalConfig = () => {
	let localConfig: ApiLocalConfig = {};

	if (window._env_.REACT_APP_NAME_ENV === 'local') {
		try {
			localConfig = require('./dev-local-config').default;
			console.warn('Using src/api/dev-local-config.ts as api.');
		} catch (error) {
			console.warn('Using default api-local-config. Create src/api/dev-local-config.ts to override.');
		}
	}

	return localConfig;
};

export const apiClient = (service: ApiService) => {
	let baseURL = `${window._env_.REACT_APP_API_BASE_URL}${service}`;

	if (window._env_.REACT_APP_NAME_ENV === 'local') {
		const localConfig = loadLocalConfig();

		if (localConfig[service]) {
			baseURL = `http://localhost:${localConfig[service]?.port}`;
		}
	}

	uniqueAxiosInstance.defaults.baseURL = baseURL;

	return uniqueAxiosInstance;
};
