import { Checkbox } from '@/components/checkbox';
import { DatePicker } from '@/components/date-picker';
import { FormControl, FormItem, FormLabel, FormMessage } from '@/components/form';
import { Input } from '@/components/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/select';
import { cn } from '@/lib/utils';
import { useI18n } from '@gle/providers.i18n';
import { DateTime } from 'luxon';
import { ControllerRenderProps } from 'react-hook-form';
import { CustomField } from '../../hooks/custom-input-fields/types';
import { useCustomSelect } from '../../hooks/use-custom-select';

type CustomInputFieldProps = {
	formField: ControllerRenderProps<any>;
	field: CustomField;
};

const getDateAsObject = (value: string) => {
	return DateTime.fromFormat(value, 'dd/mm/yyyy').isValid
		? DateTime.fromFormat(value, 'dd/mm/yyyy').toJSDate()
		: DateTime.fromISO(value).toJSDate();
};

export const CustomInputField = ({ formField, ...props }: CustomInputFieldProps) => {
	const { messages } = useI18n();

	const label = messages[props.field.labelKey] || '';
	const fieldKey = props.field.key.replace('metadata.', '');

	switch (props.field.type) {
		case 'BOOLEAN':
			return (
				<FormItem key={fieldKey} className="flex flex-row items-start space-x-2 space-y-0">
					<FormControl>
						<Checkbox checked={formField.value} onCheckedChange={formField.onChange} />
					</FormControl>
					<FormLabel className="font-normal">{label}</FormLabel>
				</FormItem>
			);

		case 'NUMBER':
			return (
				<FormItem>
					<FormLabel>{label}</FormLabel>
					<FormControl>
						<Input {...formField} min={props.field.min} max={props.field.max} type="number" />
					</FormControl>
					<FormMessage />
				</FormItem>
			);

		case 'TEXT':
			return (
				<FormItem>
					<FormLabel>{label}</FormLabel>
					<FormControl>
						<Input {...formField} type="text" />
					</FormControl>
					<FormMessage />
				</FormItem>
			);

		case 'DATE':
			return (
				<FormItem>
					<FormLabel>{label}</FormLabel>
					<DatePicker
						value={getDateAsObject(formField.value)}
						onChange={date => formField.onChange(date ? DateTime.fromJSDate(date).toISO() : null)}
					/>
					<FormMessage />
				</FormItem>
			);

		case 'SELECT':
			return (
				<FormItem>
					<FormLabel>{label}</FormLabel>
					<Select onValueChange={formField.onChange} defaultValue={formField.value}>
						<FormControl>
							<SelectTrigger className={cn({ 'text-muted-foreground': !formField.value })}>
								<SelectValue placeholder={'Seleziona una opzione'} />
							</SelectTrigger>
						</FormControl>
						<SelectContent>
							{props.field.options.map(option => (
								<SelectItem value={option.value}>{option.label}</SelectItem>
							))}
						</SelectContent>
					</Select>

					<FormMessage />
				</FormItem>
			);

		case 'CUSTOM-SELECT':
			return <CustomSelect label={label} field={props.field} formField={formField} />;

		default:
			return <></>;
	}
};

type CustomSelectProps = {
	formField: ControllerRenderProps<any>;
	field: CustomField & { type: 'CUSTOM-SELECT' };
	label: string;
};

const CustomSelect = ({ formField, ...props }: CustomSelectProps) => {
	const { options } = useCustomSelect(props.field.optionsKey);

	return (
		<FormItem>
			<FormLabel>{props.label}</FormLabel>
			<Select onValueChange={formField.onChange} value={formField.value}>
				<FormControl>
					<SelectTrigger className={cn({ 'text-muted-foreground': !formField.value })}>
						<SelectValue placeholder={'Seleziona una opzione'} />
					</SelectTrigger>
				</FormControl>
				<SelectContent>
					{options.map(option => (
						<SelectItem value={option.value}>{option.label}</SelectItem>
					))}
				</SelectContent>
			</Select>
			<FormMessage />
		</FormItem>
	);
};
