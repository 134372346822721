import { Alert, AlertDescription, AlertTitle } from '@/components/alert';
import { Button } from '@/components/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormSubmitButtons } from '@/components/form';
import { Input } from '@/components/input';
import { InputPhone, isValidPhoneNumber } from '@/components/input-phone';
import { ReadOnlyValue } from '@/components/read-only-input';
import { useToast } from '@/components/toast';
import { zodResolver } from '@hookform/resolvers/zod';
import { InfoIcon } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { setMetadata } from '../../../../../../api/people/metadata';
import { useOrgPersonEmailUpdate } from '../../../../../../hooks/use-org-person-email';

const formSchema = z.object({
	email: z.string().email('Email non valida').optional(),
	phone: z
		.string()
		.refine(value => !value || isValidPhoneNumber(value), {
			message: 'Telefono non valido',
		})
		.optional(),
});

type ContactsFormProps = {
	orgPersonId: string;
	season: string;
	initialValues: z.infer<typeof formSchema>;
	onComplete: (updatedValues: { [key in keyof z.infer<typeof formSchema>]?: boolean | undefined }) => void;
	onCancel: () => void;
	allowEdit: boolean;
	onEditStatusChanged: (isEdit: boolean) => void;
};

export const ContactsForm = (props: ContactsFormProps) => {
	const { toast } = useToast();

	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [isSaving, setIsSaving] = useState<boolean>(false);

	const saveEmail = useOrgPersonEmailUpdate();

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: props.initialValues,
	});

	const onSubmit = async (values: z.infer<typeof formSchema>) => {
		setIsSaving(true);

		try {
			if (form.formState.dirtyFields.email) {
				await saveEmail(props.orgPersonId, props.season, props.initialValues.email, values.email);
			}

			if (form.formState.dirtyFields.phone) {
				await setMetadata(
					{ orgPersonId: props.orgPersonId, season: props.season },
					{
						person: {
							contacts: {
								primaryPhone: values.phone,
							},
						},
					}
				);
			}

			toast({
				title: 'Salvataggio completato',
				description: 'I dati sono stati aggiornati con successo',
				variant: 'success',
			});

			props.onComplete(form.formState.dirtyFields);
		} catch (error) {
			toast({
				variant: 'destructive',
				title: 'Errore',
				description: 'Si è verificato un errore imprevisto, riprova più tardi',
			});
		} finally {
			setIsSaving(false);
		}
	};

	if (!isEdit) {
		return (
			<ReadOnlyContactsForm
				initialValues={props.initialValues}
				allowEdit={props.allowEdit}
				onEdit={() => {
					setIsEdit(true);
					props.onEditStatusChanged(true);
				}}
			/>
		);
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="mx-auto flex flex-col gap-4">
				<FormField
					control={form.control}
					name="email"
					render={({ field }) => (
						<FormItem className="flex flex-col">
							<FormLabel>Email di contatto per la società</FormLabel>
							<Input placeholder="" type="text" {...field} />
							<FormMessage />
						</FormItem>
					)}
				/>

				<FormField
					control={form.control}
					name="phone"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Telefono</FormLabel>
							<FormControl>
								<InputPhone
									value={field.value || undefined}
									onChange={field.onChange}
									defaultCountry="IT"
								/>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>

				<Alert variant={'warning'} className="mt-4">
					<InfoIcon className="h-4 w-4" />
					<AlertTitle>Nota</AlertTitle>
					<AlertDescription>
						Anche se modifichi questa email, la tua email per accedere all'applicazione non cambierà. Per
						modificare le credenziali di accesso all'app, vai nell'area <strong>Impostazioni</strong>.
					</AlertDescription>
				</Alert>

				<FormSubmitButtons
					isSaving={isSaving}
					onCancel={() => {
						setIsEdit(false);
						props.onEditStatusChanged(false);
					}}
				/>
			</form>
		</Form>
	);
};

const ReadOnlyContactsForm = (
	props: Pick<ContactsFormProps, 'initialValues'> & { onEdit: () => void; allowEdit: boolean }
) => {
	return (
		<div className="flex flex-col gap-4">
			<ReadOnlyValue type="text" label="Email di contatto per la società" value={props.initialValues.email} />
			<ReadOnlyValue type="text" label="Telefono" value={props.initialValues.phone} />

			{props.allowEdit && (
				<div className="flex justify-end">
					<Button variant="secondary" onClick={props.onEdit} type="button">
						Modifica contatti
					</Button>
				</div>
			)}
		</div>
	);
};
