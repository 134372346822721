import { Moon, Sun } from 'lucide-react';
import { useTheme } from './theme-provider';

export function ThemeToggle() {
	const { setTheme, theme } = useTheme();

	return (
		<div
			onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
			className={
				'mb-3 flex cursor-pointer items-center gap-3 rounded-md px-2 py-2 text-foreground hover:bg-accent lg:py-1'
			}>
			<Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
			<Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
			<p className="lg:text-sm">Tema</p>
		</div>
	);

	// return (
	// 	<DropdownMenu>
	// 		<DropdownMenuTrigger asChild>
	// 			<Button variant="outline" size="icon" {...buttonProps}>
	// 				<Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
	// 				<Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
	// 				<span className="sr-only">Toggle theme</span>
	// 			</Button>
	// 		</DropdownMenuTrigger>
	// 		<DropdownMenuContent align="end" {...contentProps}>
	// 			<DropdownMenuItem onClick={() => setTheme('light')}>Light</DropdownMenuItem>
	// 			<DropdownMenuItem onClick={() => setTheme('dark')}>Dark</DropdownMenuItem>
	// 			{/* <DropdownMenuItem onClick={() => setTheme('system')}>System</DropdownMenuItem> */}
	// 		</DropdownMenuContent>
	// 	</DropdownMenu>
	// );
}
