import { getAppVersion, isFromApp } from '@golee/react-native-web-bridge';
import axios from 'axios';
import { useEffect, useState } from 'react';
import semver from 'semver';

const getMinimumVersion = async (client: 'family' | 'app') => {
	return axios.get<{ minVersion: string }>(`${window._env_.REACT_APP_API_BASE_URL}guardian/min-version/${client}`);
};

export const useMinVersionChecker = () => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isBelowMinVersion, setIsBelowMinVersion] = useState<boolean>(false);
	const [minVersion, setMinVersion] = useState<string>();

	const forceRefresh = () => {
		if (isFromApp()) {
			if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
				window.location.href = 'https://apps.apple.com/it/app/golee/id6449704513';
			} else {
				window.location.href = 'https://play.google.com/store/apps/details?id=com.goleedev.golee';
			}
		} else {
			window.location.replace(`${window.location.origin}${window.location.pathname}?forceRefresh=${Date.now()}`);
		}
	};

	const checkVersion = async () => {
		try {
			const { data } = await getMinimumVersion(isFromApp() ? 'app' : 'family');

			if (!data.minVersion) {
				throw new Error('No minimum version found');
			}

			setMinVersion(data.minVersion);
			console.warn('[version-checker]: minVersion:', data.minVersion);

			const currentVersion = isFromApp() ? getAppVersion() : window.appVersion;

			if (currentVersion && semver.lt(currentVersion, data.minVersion)) {
				setIsBelowMinVersion(true);
			}
		} catch (error) {
			console.error('Error checking minimum version:', error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		checkVersion();
	}, []);

	return {
		isLoading,
		isBelowMinVersion,
		minVersion: minVersion as string,
		forceRefresh,
	};
};
