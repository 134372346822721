import { Heading } from '@/components/heading';
import { useConan } from '@golee/gle-conan-tracker';
import { useNavigate } from 'react-router-dom';
import { getMetadata, Metadata, MetadataParams } from '../../../../../api/people/metadata';
import { NavigateBack } from '../../../../../components';
import { useOrgPerson } from '../../../../../context/org-person';
import { useApi } from '../../../../../hooks/use-api';
import { notifyDataChange } from '../../utils/notify-data-changed';
import { SportsForm } from './sports-form';

export const ProfileSportsPage = () => {
	const navigate = useNavigate();
	const { trackEvent } = useConan();
	const { orgPerson, refresh: refreshOrgPersonContext } = useOrgPerson();

	const { isLoading, data } = useApi<Metadata, MetadataParams>(getMetadata, {
		orgPersonId: orgPerson.orgPersonId,
		season: orgPerson.season,
	});

	const notifyChangesToOrganization = (updatedValues: { [key: string]: boolean | undefined }) => {
		const updatedValuesAsArray = Object.keys(updatedValues).filter(key => !!updatedValues[key]);

		if (updatedValuesAsArray.length) {
			notifyDataChange(orgPerson, updatedValuesAsArray as string[]);
			void trackEvent('family.orgPerson-changed-data-from-app', {
				values: updatedValuesAsArray.join(', '),
				section: 'sport',
			});
		}
	};

	return (
		<div className="pb-4">
			<NavigateBack to={'/profile'} className="mb-4" />

			<Heading level={2} className="mb-4">
				Dati sportivi
			</Heading>

			{isLoading ? (
				<>loading...</>
			) : (
				<SportsForm
					orgPersonId={orgPerson.orgPersonId}
					season={orgPerson.season}
					onCancel={() => {
						navigate('/profile');
					}}
					onComplete={() => {
						refreshOrgPersonContext();
						navigate('/profile');
					}}
					initialValues={{
						person: data?.person || {},
						athlete: data?.athlete || {},
						groups: orgPerson.groups || [],
					}}
				/>
			)}
		</div>
	);
};
